/* body{
  background-image: url(../../../images/panel/back.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200vh;
} */
.content-busqueda{
  background-color: #ebebeb;
  /* background-image: url('../../../images/panel/back.png'); */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 24%;
}
.content-busqueda .contenido {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
}


@media all and (max-width: 1200px) {
  .content-busqueda .contenido {
    max-width: 1024px;
  }
}

@media all and (max-width: 1024px) {
  .content-busqueda .contenido {
    max-width: 800px;
  }
}

.content-busqueda .contenido  .content-table{
  width: 100%;
  position: relative;
  padding-top: 90px;
}

/* div#busqueda_length {display: none;} */

.dataTables_wrapper .dataTables_filter input{
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
}
.table_busqueda {
  border-collapse: separate;
  border-spacing: 0 15px !important;
  width: 100% !important;
}

.table_busqueda th {
  padding: 15px 10px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 5px 0;
  border: none;
  color: #003967;
  font-weight: 400;
  font-size: 14px;
}

.table_busqueda tr {
  border-spacing: 5;
  border: none;
}

.table_busqueda td {
  background-color: #fff;
}

.table_busqueda .FINALIZADA td,
.table_busqueda .finalizada td,
.table_busqueda .finalizado td {
  background-color: #d6d6d6;
}

.table_busqueda td {
  color: #0078c6;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle !important;
}

.table_busqueda td .color-estado {
  width: 20px;
  height: 20px;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto;
  line-height: 1;
  text-align: center;
  color: #000;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-weight: 700;
}
.table_busqueda td .color-estado[data-estado="Rechazada"] {
  background-color: #b44949;
  border: 1px solid #b44949;
  color: #b44949;

}

.table_busqueda td .color-estado[data-estado="En proceso"] {
  background-color: #508e66;
  border: 1px solid #508e66;
  color: #508e66;
}
.table_busqueda td .color-estado[data-estado="Pendiente"] {
  background-color: #b1a547;
  border: 1px solid #b1a547;
  color:#b1a547;
}

.table_busqueda td .estado_general .Pendiente,
.table_busqueda td .estado_general .PENDIENTE {
  color:#5d5c5c;
  font-weight: 700;
}
.table_busqueda td .estado_general .En.proceso,
.table_busqueda td .estado_general .EN.PROCESO {
  color: #30894f;
  font-weight: 700;
}
.table_busqueda td .estado_general .Rechazada,
.table_busqueda td .estado_general .RECHAZADA {
  color: #b44949;
  font-weight: 700;
}
.table_busqueda td .estado_general .Finalizada,
.table_busqueda td .estado_general .FINALIZADA {
  color: #0078c6;
  font-weight: 700;
}
.table_busqueda td .estado_general .Pausado,
.table_busqueda td .estado_general .PAUSADO {
  color: #b1a547;
  font-weight: 700;
}
.table_busqueda td .estado_general .Incompleta,
.table_busqueda td .estado_general .INCOMPLETA {
  color: #92491f;
  font-weight: 700;
}

.table_busqueda td .puesto {
  font-weight: 700;
  color: #0078c6;
}
.table_busqueda td .estado_general {
  text-transform: uppercase;
}

.table_busqueda  td .contet-director {
  /* display: flex; */
  align-items: center;
}
.table_busqueda td .pausado {
  background-color: #b1a547;
  color: #b1a547;
}

.table_busqueda td .aprovado {
  background-color: #508e66;
  color: #508e66;
}

.table_busqueda td .rechazado {
  background-color: #b44949;
  color: #b44949;
}

.table_busqueda td .espera {
  background-color: #fff;
  /* border: 1px solid;  */
  color: #000;
}
.table_busqueda .pendiente td .estado-pendiente {
  color: #5d5c5c;
}
.table_busqueda .FINALIZADA td .estado-proceso,
.table_busqueda .finalizada td .estado-proceso,
.table_busqueda .finalizado td .estado-proceso {
  color: #30894f;
}

.table_busqueda .pendiente td .estado-rechazada {
  color: #b44949;
}

.table_busqueda .pendiente td .estado-finalizada {
  color: #0078c6;
}

.table_busqueda .pendiente td .estado-pausada {
  color: #b1a547;
}
.content-filtrar {
  display: block;
  align-items: center;
  margin: 16px 0;

  position: absolute;
  top: 0;
  left: 0;
}

.content-filtrar .filtrar-span {
  color: #0078c6;
  font-weight: 700;
  font-size: 16px;
}

.content-filtrar select {
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  border: none;
  padding: 5px;
  width: 243px;
  color: #8c8b8b;
  margin-left: 15px;
}
.search {
  position: absolute;
  width: 300px !important;
  top: 5px;
  right: 0;
  margin: 20px 0 10px;
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.table_busqueda {
  border: none;
}

.table_busqueda tr th, .table_busqueda tr td {
  border: 0px !important;
  font-size: 14px;
  vertical-align: middle !important;
  text-align: center;
}
.table_busqueda tr th {
  padding: 15px 10px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 5px 0;
  border: none;
  color: #003967;
  font-weight: 400;
  font-size: 14px;
}

.page-item{
  cursor: pointer;
}


@media all and (max-width: 700px) {
  .content-filtrar{
    margin: 0 !important;
  }
  .search{
    top: 40px;
    left: 0;
  }
  .content-busqueda .pagination{
    flex-wrap: wrap;
  }
}
@media all and (max-width: 500px) {
  .content-filtrar{
    display: flex;
  }
  /* .content-filtrar .filtrar-span{

  } */
  .content-filtrar select{
    width: 180px !important;
  }
  div#busqueda_filter{
    display: flex;
  }
  .content-filtrar .filtrar-span{
    font-size: 14px !important;
  }
  
}