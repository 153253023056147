.content-formulario {
  background-color: #ebebeb;
  width: 100%;
}

.content-formulario .formulario form .content-datos {
  border-top: 1px solid #8c8b8b;
  border-bottom: 1px solid #8c8b8b;
  width: 100%;
  margin: 15px 0;
}

.content-formulario .formulario {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3% 0px;
}

.content-formulario .formulario form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Propiedad cambiada de end a flex-end *//* Propiedad cambiada de flex-end a flex-start */
  padding: 10px 0;
}

.content-formulario .formulario form #asignar {
  width: 49%;
  height: 45px;
  padding: 5px;
  color: #0078c6;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 5px;
}

.content-formulario .formulario form .content-datos input {
  width: 100% !important;
  height: 45px;
  border: none;
  border-radius: 5px;
  margin: 5px 0 15px;
  outline: none;
  padding: 5px 15px;
  color: #5d5c5c;
}
.content-formulario .formulario form .content-datos .content-linea {
  display: flex;
  justify-content: space-between;
}
.content-formulario .formulario form .content-datos .content-linea .opciones {
  display: flex;
  flex-direction: column;
}

.content-formulario .formulario form .content-datos .content-linea .opciones button.marcar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 5px 40px 5px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 15px 0;
  outline: none;
}

.content-formulario .formulario form .content-datos .content-linea .opciones div.marcar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 5px 40px 5px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 15px 0;
  outline: none;
  cursor: pointer;
  text-align: center;
}

.content-formulario .formulario form .content-datos .content-linea .opciones div.marcar.marcado{
  color: #2cb5a5;
  border: 1px solid #2cb5a5;
}

.content-formulario .formulario form .content-datos .content-linea .opciones div.marcar img {
  position: absolute;
  top: 4px;
  right: 7px;
  width: 100%;
  max-width: 25px;
}

.content-formulario .formulario form .content-datos .content-linea .opciones div.resaltar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 8px 40px 8px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 15px 0 15px;
  outline: none;
  cursor: pointer;
  text-align: center;
}

.newapplicant .content-datos select  {
  width: 100% !important;
  height: 45px;
  border: none;
  border-radius: 5px;
  margin: 15px 0;
  outline: none;
  padding: 5px 15px;
  color: #5d5c5c;
}

.content-formulario .formulario form .content-datos .content-linea .opciones div.resaltar.resaltado {
  border: 1px solid #2cb5a5;
  color: #2cb5a5;
}

.content-formulario .formulario form .content-datos .content-linea .opciones div.resaltar img {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 100%;
  max-width: 22px;
}

.content-formulario .formulario form .content-datos .content-linea .opciones button.marcar img {
  position: absolute;
  top: 4px;
  right: 7px;
  width: 100%;
  max-width: 25px;
}
.content-formulario .formulario form .content-datos .content-linea .opciones button.resaltar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 5px 40px 5px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 0 0 15px;
  outline: none;
}

.content-formulario .formulario form .content-datos .content-linea .opciones button.resaltar img {
  position: absolute;
  top: 4px;
  right: 7px;
  width: 100%;
  max-width: 25px;
}
.newapplicant .botones {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.botones span {
  color: #5d5c5c;
  display: block;
  margin: 5px 0;
  font-size: 12px;
}

.botones button.curriculum {
  background-color: #8c8b8b;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  margin: 15px 0 0;
  font-weight: 500;
  outline: none;
  line-height: 36px;
  width: 300px;
}

.botones button.curriculum img {
  margin-right: 10px;
  border: none;
  width: 100%;
  max-width: 18px;
}

.botones button.crear-btn-public {
  padding: 12px 15px;
  background-color: #0D2055;
  color: #ffff;
  font-weight: 500;
  border-radius: 5px;
  outline: none;
  line-height: 36px;
  margin: 15px 0 0;
  border-radius: 2.5rem;
  font-size: 1.25rem;
  min-width: 14.625rem;
  padding: .65rem 1.75rem;
  font-weight: 700;
}

.modal_candidato .modal-header{
  background-image: url('../../../images/menu/banner_modal.png');
  background-size: cover;
  height: 72px;
  background-repeat: no-repeat;
}
.content-formulario .formulario form .content-datos .content-linea .input-error label {
  font-weight: 700;
  color: #0078c6;
  margin: 14px 0 0;
  font-size: 13px;
}
@media all and (max-width: 1024px) {
  .content-formulario .formulario{
    max-width: 800px !important;
    padding: 3% !important;
  }
}
@media all and (max-width: 755px) {
  .content-formulario .formulario form .content-datos{
      flex-direction: column;
  }
  .content-formulario .formulario form .content-datos .content-linea{
      flex-direction: column;
  }
  .content-formulario .formulario form .content-datos input{
      width: 100% !important;
  }
  .content-formulario .formulario form #asignar{
      width: 100%;
  }
  .content-formulario .formulario form .content-datos .content-linea .input-error{
    width: 100% !important;
  }
}