.content-formulario-completar {
  background-color: #ebebeb;
  width: 100%;
}

.content-formulario-completar .formulario  .content-datos {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 100%;
  margin: 15px 0;
}

.content-formulario-completar .formulario {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3% 0px;
}

.content-formulario-completar .formulario  {
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Propiedad cambiada de end a flex-end *//* Propiedad cambiada de flex-end a flex-start */
}

.content-formulario-completar .formulario  #asignar {
  width: 49%;
  height: 45px;
  padding: 5px;
  color: #0078c6;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 5px;
}

.content-formulario-completar.aplicant_external .formulario .content-datos.seccion-previa{
  background-color: #dadada;
  border-radius: 15px;
  padding: 5px 13px;
}

.content-formulario-completar.aplicant_external .formulario .content-datos .titulo{
  text-align: center;
  color: #0078c6;
  margin: 10px 0 15px;
  font-weight: 600;
}

.content-formulario-completar.aplicant_external .formulario .content-datos input,
.content-formulario-completar.aplicant_external .formulario .content-datos select {
  width: 100% !important;
  height: 45px;
  border: none;
  border-radius: 5px;
  margin: 5px 0 15px;
  outline: none;
  padding: 5px 15px;
  color: #5d5c5c;
}
.content-formulario-completar .formulario  .content-datos .content-linea {
  display: flex;
  justify-content: space-between;
}
.content-formulario-completar .formulario  .content-datos .content-linea .opciones {
  display: flex;
  flex-direction: column;
}

.content-formulario-completar .formulario  .content-datos .content-linea .opciones button.marcar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 5px 40px 5px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 15px 0;
  outline: none;
}

.content-formulario-completar .formulario  .content-datos .content-linea .opciones button.marcar img {
  position: absolute;
  top: 4px;
  right: 7px;
  width: 100%;
  max-width: 25px;
}
.content-formulario-completar .formulario  .content-datos .content-linea .opciones button.resaltar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 8px 40px 8px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 0 0 15px;
  outline: none;
}

.content-formulario-completar .formulario  .content-datos .content-linea .opciones button.resaltar img {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 100%;
  max-width: 22px;
}
.newapplicant .botones {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.botones span {
  color: #5d5c5c;
  display: block;
  margin: 5px 0;
  font-size: 12px;
}
.select-universidad:disabled{
  background-color: #E0E0E0;
  color: #5D5C5C;
}
.botones button.curriculum, .botones div.curriculum {
  background-color: #8c8b8b;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  margin: 15px 0 0;
  font-weight: 500;
  outline: none;
  line-height: 36px;
  cursor: pointer;
  width: 300px;
}

.botones .curriculum img {
  margin-right: 10px;
  border: none;
  width: 100%;
  max-width: 18px;
}

.botones button.crear {
  padding: 12px 15px;
  border: none;
  background-color: #006bb0;
  color: #ffff;
  font-weight: 500;
  border-radius: 5px;
  outline: none;
  line-height: 36px;
  margin: 15px 0 0;
}
.content-formulario-completar .formulario span.Complete {
  color: #0078c6;
  font-size: 32px;
  line-height: 1;
  font-weight: 600;
  display: block;
}

.content-formulario-completar .formulario span.Complete span {
  color: #003967;
}
.content-formulario-completar .formulario  .content-datos .content-linea .input-error {
  width: 32%;
}

.content-formulario-completar .formulario  .content-datos .content-linea .input-long {
  width: 49%;
}

.content-formulario-completar .formulario  .content-datos .content-linea .input-long .title,
.content-formulario-completar .formulario  .content-datos .content-linea .input-error .title {
  font-size: 15px;
  color: #0078c6;
  font-weight: 600;
  padding-left: 5px;
}

.content-formulario-completar .formulario  .content-datos .content-linea .input-long .title span {
  font-weight: 200;
}

.content-formulario-completar .formulario  .content-datos .content-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-formulario-completar .formulario  .content-datos .content-image .input.image{
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 400px;
  background-color: #d6d6d6;
  align-items: center;
}


.content-formulario-completar .formulario  .content-datos .content-image .input .title {
  font-size: 15px;
  color: #0078c6;
  font-weight: 600;
  padding-left: 5px;
  margin: 15px 10px 0;
}

.content-formulario-completar .formulario  .content-datos .content-image .input .line {
  height: 80px;
  display: flex;
  align-items: center;
  width: 100%;
}

.content-formulario-completar .formulario  .content-datos .content-image .input .button_file {
  background-color: #8c8b8b;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  font-weight: 500;
  outline: none;
  line-height: 36px;
  cursor: pointer;
  /* width: 150px; */
}

.content-formulario-completar .formulario  .content-datos .content-image .input .name_file {
  /* margin-left: 15px; */
  
}

.content-formulario-completar .formulario  .content-datos .content-image .input .view_image {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 500px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 10px 0 15px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(156,156,156,0.9);
  -moz-box-shadow: 0px 3px 5px 0px rgba(156,156,156,0.9);
  box-shadow: 0px 3px 5px 0px rgba(156,156,156,0.9);
}

.content-formulario-completar .formulario  .content-datos .content-image .input .button_send,
.content-formulario-completar .formulario  .content-datos .content-image .input .button_back {
  background-color: #0078c6;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  font-weight: 500;
  outline: none;
  line-height: 36px;
  cursor: pointer;
  width: 150px;
  text-align: center;
}

.content-formulario-completar .formulario  .content-datos .content-image .input .button_back {
  background-color: #ce0000;
}

.content-formulario-completar .formulario  .content-datos .content-image .input {
  display: flex;
  flex-direction: row;
}

.content-formulario-completar .formulario  .content-datos .content-linea .inputbuttons {
  display: flex;
}

.content-formulario-completar .formulario  .content-datos .content-linea .inputbuttons .button_checked,
.content-formulario-completar .formulario  .content-datos .content-linea .inputbuttons .button_edit,
.content-formulario-completar .formulario  .content-datos .content-linea .inputbuttons .button_cancel {
  background-color: #0078c6;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  font-weight: 500;
  outline: none;
  line-height: 36px;
  cursor: pointer;
  width: 150px;
  text-align: center;
}

.content-formulario-completar .formulario  .content-datos .content-linea .inputbuttons .button_checked {background-color: #42aa06;}
.content-formulario-completar .formulario  .content-datos .content-linea .inputbuttons .button_cancel {background-color: #ce0000;}

.custom-tooltip .tooltip-inner {
  color: black; 
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px; 
  padding: 8px; 
}

@media all and (max-width: 1200px) {
  .content-formulario-completar .formulario{
    max-width: 1024px;
  }
}

@media all and (max-width: 755px) {
  .content-formulario-completar .formulario  .content-datos{
      flex-direction: column;
  }
  .content-formulario-completar .formulario  .content-datos .content-linea{
      flex-direction: column;
  }

  .content-formulario-completar .formulario  .content-datos .content-linea .input-long,
  .content-formulario-completar .formulario  .content-datos .content-linea .input-error{
    width: 100%;
  }
  .content-formulario-completar .formulario  .content-datos input{
      width: 100%;
  }
  .content-formulario-completar .formulario  #asignar{
      width: 100%;
  }

  .content-formulario-completar .formulario  .content-datos .content-image {
    justify-content: center;
    flex-direction: column;
  }

  .content-formulario-completar .formulario  .content-datos .content-image .input{
    margin: 10px 0;
  }
}