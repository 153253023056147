/* body{
  background-image: url(../../../images/panel/back.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200vh;
} */
.warning-message{
  color: red;
  font-weight: 700;
  font-size: 12px;
}
.content-aprobar{
    background-image: url('../../../images/panel/back.png');
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content-aprobar .contenido {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
  }
  
  /* Botones de alta y baja*/
  .search-button#search{
    border: none;
    background-color: #006bb0;
    color: #fff;
    padding: 10px 23px;
    margin: 0 5px;
    font-weight: 700;
    outline: none;
  }
  .parrafo-alta-user{
    font-size: larger;
  }
  /* div#aprobar_length {display: none;} */
  .dataTables_wrapper .dataTables_filter input{
    
  background-color: rgba(255, 255, 255, 0.5);
    
  outline: none;
  }
  table#aprobar {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100% !important;
  }
  
  table#aprobar th {
    padding: 15px 10px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 5px 0;
    border: none;
    color: #003967;
    font-weight: 400;
    font-size: 14px;
  }
  
  table#aprobar tr {
    border-spacing: 5em;
    border: none;
  }
  
  #aprobar .pendiente td {
    background-color: #fff;
  }
  #aprobar .FINALIZADA td,
  #aprobar .finalizada td,
  #aprobar .finalizado td {
    background-color: #d6d6d6;
  }
  
  #aprobar td {
    color: #0078c6;
    padding-left: 10px;
    padding-right: 10px
  }
  
  #aprobar td .color-estado {
    width: 20px;
    height: 20px;
    display: flex;
    background-color: red;
    border-radius: 50%;
    margin-right: 14px;
    line-height: 1;
    text-align: center;
    color: #000;
    justify-content: center;
    align-items: center;
  }
  #aprobar .FINALIZADA td .contet-director,
  #aprobar .finalizada td .contet-director,
  #aprobar .finalizado td .contet-director {
    /* display: flex; */
    align-items: center;
  }
  #aprobar .pendiente td .contet-director {
    /* display: flex; */
    align-items: center;
  }
  #aprobar td .PAUSADA,
  #aprobar td .pausada,
  #aprobar td .pausado {
    background-color: #b1a547;
    color: #b1a547;
  }
  
  #aprobar td .aprovado {
    background-color: #508e66;
    color: #508e66;
  }
  
  #aprobar td .rechazado {
    background-color: #b44949;
    color: #b44949;
  }
  
  #aprobar td .espera {
    background-color: #fff;
    border: 1px solid;
    color: #000;
  }
  #aprobar .pendiente td .estado-pendiente {
    color: #5d5c5c;
  }
  
  #aprobar .FINALIZADA td .estado-proceso,
  #aprobar .finalizada td .estado-proceso,
  #aprobar .finalizado td .estado-proceso {
    color: #30894f;
  }
  
  #aprobar .pendiente td .estado-rechazada {
    color: #b44949;
  }
  
  #aprobar .pendiente td .estado-finalizada {
    color: #0078c6;
  }
  
  #aprobar .pendiente td .estado-pausada {
    color: #b1a547;
  }
  .content-filtrar {
    display: block;
    align-items: center;
    margin: 16px 0;
  }
  
  .content-filtrar .filtrar-span {
    color: #0078c6;
    font-weight: 700;
    font-size: 16px;
  }
  
  .content-filtrar select {
    background-color: rgba(255, 255, 255, 0.5);
    outline: none;
    border: none;
    padding: 5px;
    width: 243px;
    color: #8c8b8b;
    margin-left: 15px;
  }
  @media all and (max-width: 500px) {
    .content-filtrar{
      display: flex;
    }
    /* .content-filtrar .filtrar-span{
  
    } */
    .content-filtrar select{
      width: 200px;
    }
    div#aprobar_filter{
    display: flex;
    }
  }

  .content-filtrar.departament2{
    margin-top: 70px;
  }