/* body{
  background-image: url('../../images/panel/back.png');
  background-size: cover;
} */

.content-body{
 /*  background-image: url('../../images/panel/back.png'); */
  background-image: url('../../images/panel/back.png');
  background-size: cover;
  width: 100%;
}
.content-body .contenido {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5% 0 7%;
  display: flex;
  flex-wrap: wrap;
}

.content-body .contenido .modulo {
  cursor: pointer;
  width: 360px;
  height: 230px;
  background-color: #fff;
  margin: 15px 10px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.content-body .contenido .modulo:hover {
  opacity: 0.9;
}

.content-body .contenido .modulo span {
  font-weight: 400;
  font-size: 30px;
  width: 177px;
  line-height: 1;
}

.content-body .contenido #crear-busqueda {
  background-color: #0078c6;
}

.content-body .contenido #crear-busqueda span {
  color: #fff;
}

.content-body .contenido .modulo .barra {
  width: 175px;
  height: 5px;
  background-color: #7fc4fd;
  margin-top: 20px;
}



.content-body .contenido #busqueda span {
  color: #0078c6;
}

.content-body .contenido #candidatos span {
  color: #003967;
}

.content-body .contenido #crear-candidato {
  background-color: #e3e2e2;
}

.content-body .contenido #crear-candidato span {
  color: #003967;
}

.content-body .contenido #reporte {
  background-color: #d2d1d1;
}
.content-body .contenido #aprobar {
  background-color: #143a62;
}
.content-body .contenido #aprobar span {
  color: #fff;
}

.content-body .contenido #reporte span {
  color: #003967;
}
.content-body .contenido a {
  text-decoration: none;
}
@media all and (max-width: 1200px) {
  .content-body .contenido{
      justify-content: center;
  }
}

@media all and (max-width: 400px) {
  .content-body .contenido{
      justify-content: center;
  }

  .content-body .contenido .modulo {
    margin: 10px;    
    width: 310px;
    height: 210px;
  }

  .content-body .contenido .modulo span {
    
    font-size: 24px;
   
  }
}

.contenido-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 10px;
}

.contenido-actions .action {
  background-color: #004c80;
  border: none;
  margin: 0;
  display: inline-block;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}