.notes-section {
    padding: 20px;
    background-color: #fff;
}



.btn-action{
  background-color: #004c80 !important;
  color: #fff !important;
  border-radius: 0 !important;
  border-color: #004c80 !important;
}

.notes-section .table_view_process thead tr :last-child {
  text-align: left !important;
}


.notes-section .table_view_process .icon-action img{
    width: 25px;
    cursor: pointer;
    transition: all .3s ease;
}

.notes-section .table_view_process .icon-action img.arrow_up {
    transform: rotateZ(180deg);
}

.notes-section .truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notes-section .details-column .delete-icon{
  display: flex;
  align-items: center;
  color: #D50000;
  font-size: 17px;
  text-decoration: none;
  font-weight: 500;
}
.notes-section .details-column .delete-icon img{
  margin-right: 5px;
  width: 20px;
  margin-bottom: 3px;
}


.delete-modal-backgrop{
  z-index: 1050 !important;
}

.lntobr {
  white-space: pre-wrap !important;
} 

