.productBanner{
  width: 100%;
}
.productBanner .bannerImage {
  /*/ descontando navbar
  height: 140px;
  /*/
  height:260px;
  position: relative;
  background-repeat: no-repeat;

}
.productBanner .bannerMask:before{
  content: "";
  opacity: .7;
  background-color: #0D2055;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.productBanner .bannerMask-noblue:before{
  content: "";
  opacity: .7;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.productBanner .productTitleEmpleos {
  font-size: 1.75rem;
  bottom: 35% !important;
  color: #0E1E53 !important;
  position: absolute;
  /*bottom: 25%;*/
  /*border-bottom: var(--secondary) 2px solid;*/
  line-height: 1.25;
  max-width: 68%;
  font-weight: 400 !important;
}
.title-bold{
  font-weight: 800 !important;
}

@media(max-width: 992px){
.productBanner .productTitle{
  bottom: 20% !important;
}
}
@media(max-width: 500px){
  .productBanner .productTitleEmpleo{
    color: white !important;
    line-height: 1 !important;
  }
}

.productBanner .productTitle.bbottom b{
  white-space: nowrap;
  /*border-bottom: var(--secondary) 2px solid;
*/
  position: relative;
  overflow-x: hidden;
}
.productBanner .productTitle span.underline:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  animation-name: underlinegrow;
  animation-duration: 1s;
  transform-origin: left;
}
.productBanner .productTitleEmpleos  span.underline:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent !important;
  animation-duration: 1s;
  transform-origin: left;
}

.productBanner .productTitle.bbottom b:after {
  /*border-bottom: var(--secondary) 2px solid;*/
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  animation-name: underlinegrow;
  animation-duration: 1s;
  transform-origin: left;
}
.productBanner .productTitleEmpleos.bbottom b:after {
  /*border-bottom: var(--secondary) 2px solid;*/
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: none;
  animation-duration: 1s;
  transform-origin: left;
}

.productBanner .last-line .last-line-row{
  position: relative;
  overflow-x: hidden;
  /*border-bottom: var(--secondary) 2px solid;*/
}


.productBanner .bannerTitleContainer .last-line .last-line-row::after{
  display: none;
}
.productBanner .bannerTitleContainer span{
  position: relative;
}

.productBanner .bannerTitleContainer {
  position: absolute;
  bottom: 9%;

}
.productBanner .bannerTitleContainer .productTitle {
  position: relative;
  bottom: unset;
  max-width: none;
  /*font-size: 1.5rem;*/
}

.productBanner .last-line .last-line-row::after,
.productBanner .bannerTitleContainer span:after
{
  /*border-bottom: var(--secondary) 2px solid;*/
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  animation-name: underlinegrow;
  animation-duration: 1s;
  transform-origin: left;
}

.productBanner .last-line .last-line-row::after,
.productBanner .bannerTitleContainer span:after
{
  /*border-bottom: var(--secondary) 2px solid;*/
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  animation-name: underlinegrow;
  animation-duration: 1s;
  transform-origin: left;
}


@keyframes underlinegrow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.productBanner .textContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0D2055;
 /* min-height: 124px;*/
  min-height: 80px;
  /*padding: 2rem 0;*/
  padding: 1.5rem 0;
}
.productBanner .bannerTitleContainer .bannerDesc b{
  font-weight: 300!important;
}
.productBanner .bannerTitleContainer .bannerDesc,
.productBanner .bannerText {
  color: white;
  text-align: left;
  /*max-width:360px;*/
  line-height: 1.125;
  font-size: 1.25rem;
  padding: 0;
}
.productBanner .bannerTitleContainer .bannerDesc {
  font-size: 1.125rem;
  margin-top: 15px;
  font-weight: 300;
}

.productBanner .bannerText p{
  margin: 0;
  /*font-weight: 700;*/
}
.productBanner .bannerText p br{
  display: none;
}
.productBanner .highlightsbold{
  color: var(--secondary);
}
.productBanner .highlightsbold.white{
  color: var(--white);
}


@media (min-width: 576px) {

  .productBanner .bannerText{
    font-size: 1.5rem;
    padding: 1rem 0;
  }

  .productBanner .bannerTitleContainer{
    font-size: 1.5rem;
  }
  .productBanner .bannerTitleContainer .bannerDesc {
    font-size: 1.25rem;
  }
  .productBanner .productTitleEmpleo{
    color: white;
    line-height: 1;
  }

}

@media (min-width: 768px) {

  .productBanner .productTitle span.underline:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 0;
    background-color: transparent;
    animation-name: none;
  }

  .productBanner .productTitle {
    /*max-width: 26%;*/
    max-width: 36%;
    /*font-size: 1.75rem;*/
    font-size: 2.5rem
  }
  .productBanner .productTitleEmpleos  {
    max-width: 34%;
    font-size: 2.5rem
  }
  .productBanner.bannerAhorrar .productTitle{
    max-width: 45%;
  }

  .productBanner .bannerTitleContainer{
   /* max-width: 100%;*/
  }
  .productBanner .bannerTitleContainer .productTitle {
    max-width: 100%;
  }
  /*.productBanner .bannerTitleContainer .bannerDesc{
    font-size: 1.35rem;
  }*/

  .productBanner .bannerImage {
    /*/ descontando navbar
    height: 189px;
     */
    height: 277px;
  }
  .productBanner .bannerText {
   /* font-size: 1.125rem;*/
    /*text-align: center;*/
   /* max-width:70%;*/
    margin: auto;
    font-size: 1.35rem;
    padding: 0;
  }
  .productBanner .textContainer {
    min-height: 150px;
  }
  .productBanner .bannerText p br{
    display: inline;
  }
  .productBanner .bannerTitleContainer .bannerDesc{
    font-size: 1.5rem;
  }
}


@media (min-width: 992px) {
  .productBanner .bannerImage {
    /*/ descontando navbar
    height: 239px;
     */
    /*height: 327px;*/
    height: 289px;
 }
 .productBanner .bannerText {
   /*max-width:60%;*/
  /* font-size: 2rem;*/
    font-size: 1.5rem;
    padding: .5rem 0;
  }

  /*.productBanner .textContainer {
    height: 140px;
  }*/

  .productBanner .bannerTitleContainer{
    /*max-width: 70%;*/
    bottom: 18%;
  }

  .productBanner .bannerTitleContainer .bannerDesc{
    /*font-size: 1.7rem;*/
    font-size: 1.85rem;
  }
}

@media (min-width: 1200px) {
  .productBanner .bannerImage {
    /*/ descontando navbar
    height: 289px;
     */
    /*height: 377px;*/
    height: 327px;
  }
  .productBanner .bannerText {
   /* font-size: 2rem;*/
    /*max-width:60%;*/
    font-size: 1.75rem;
    padding: 1.5rem 0;
  }

  .productBanner .bannerTitleContainer .bannerDesc{
    font-size: 2rem;
  }

  /*.productBanner .textContainer {
    height: 170px;
  }*/
  .productBanner .productTitle {
    /*font-size: 2.5rem;*/
    font-size: 3rem;
  }

 /* .productBanner .bannerTitleContainer .bannerDesc{
    font-size: 2.75rem;
  }*/
}

@media (min-width: 1400px) {
  .productBanner .productTitle {
    /*font-size: 3rem;*/
    font-size: 4rem;
  }
  .productBanner .bannerImage {
    /*/ descontando navbar
    height: 337px;
     */
    /*height: 425px;*/
    height: 530px !important;
  }
  .productBanner .bannerText {
    /*font-size: 2.25rem;*/
    font-size: 2rem;
    padding: 2rem 0;
  }
  /*.productBanner .textContainer {
    height: 200px;
  }*/
  .productBanner.bannerAhorrar .productTitle{
    max-width: 39%;
  }

   .productBanner .bannerTitleContainer .bannerDesc{
    font-size: 2.25rem;
  }
}

/*
@media (min-width: 1920px) {
  .productBanner .productTitle {
    font-size: 4rem;
  }
  .productBanner .bannerImage {
    height: 550px;
  }
  .productBanner .bannerText {
    font-size: 2.5rem;
    padding: 3rem 0;
  }
}*/


.base-text {
  font-size: 1rem;
  color: #0d2055;
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 576px) {
  .base-text {
      font-size: 1.125rem;
  }
}

@media (min-width: 768px) {
  .base-text {
      font-size: 1.125rem;
  }
}

@media (min-width: 992px) {
  .base-text {
      font-size: 1.125rem;
  }
}

@media (min-width: 1200px) {
  .base-text {
      font-size: 1.25rem;
  }
}