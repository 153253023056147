/* body{
  background-image: url(../images/panel/back.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200vh;
} */

.content-compensacion {
  background-color: #ebebeb;
  /* background-image: url('../../../../images/panel/back.png'); */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  padding-bottom: 20px;
}

.content-compensacion .compesacion-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0 10px;
}

.content-compensacion .compesacion-header span.ruta {
  color: #003967;
  font-size: 16px;
}

b.cargo {
  color: #0078c6;
}

.content-compensacion .compesacion-header span.volver {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #8c8b8b;
  font-size: 16px;
}

.content-compensacion .compesacion-header span.volver img {
  display: block;
  margin-right: 10px;
  max-width: 14px;
}

.content-compensacion .formulario-compensacion span.editar {
  position: absolute;
  right: 40px;
  top: 11px;
  display: none;
  align-items: center;
  color: #bababa;
  font-size: 16px;
  cursor: pointer;
}

.content-compensacion .formulario-compensacion span.editar img {
  width: 100%;
  max-width: 18px;
  margin-left: 5px;
}

.content-compensacion .formulario-compensacion {
  background-color: #fff;
  padding: 50px 40px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content-compensacion .formulario-compensacion span.datos {
  font-size: 16px;
  color: #003967;
  font-weight: 400;
}

.content-compensacion .formulario-compensacion span.datos b {
  color: #0078c6;
  font-size: 16px;
}

.content-compensacion .formulario-compensacion hr.doble {
  border-top: 2px solid #0078c6;
}

.content-compensacion .formulario-compensacion .descripcion {
  display: flex;
  flex-direction: column;
}

.content-compensacion .formulario-compensacion .descripcion span {
  display: block;
  margin: 0px 0px 10px;
  font-size: 16px;
  color: #003967;
  font-weight: 400;
}

.content-compensacion .formulario-compensacion .descripcion p {
  color: #0078c6;
  width: 100%;
  max-width: 460px;
  font-weight: 700;
  line-height: 1.2;
}
.content-compensacion .formulario-compensacion .botones {
  display: flex;
}

.content-compensacion .formulario-compensacion .botones button {
  padding: 12px 28px;
  margin: 0 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-weight: 700;
  outline: none;
}

.content-compensacion .formulario-compensacion .botones button:hover {
 opacity: 0.5;
}

.content-compensacion .formulario-compensacion .botones button#aprobar {
  background-color: #508e66;
}

.content-compensacion .formulario-compensacion .botones button#pausar {
  background-color: #b1a547;
}

.content-compensacion .formulario-compensacion .botones button#negar {
  background-color: #b44949;
}
.content-compensacion .formulario-compensacion .botones button#copiar {
  background-color: #0078c6;
}
.content-compensacion .formulario-compensacion .botones button#pausar.active {
  -webkit-box-shadow: -1px -1px 26px 0px rgba(177,165,71,1);
  -moz-box-shadow: -1px -1px 26px 0px rgba(177,165,71,1);
  box-shadow: -1px -1px 26px 0px rgba(177,165,71,1);
}
.content-compensacion .compesacion-header span.volver a {
  color: #8c8b8b;
  text-decoration: none;
}
.modal_approve .modal-header{
  background-image: url('../../../../images/menu/banner_modal.png');
  background-size: cover;
  height: 72px;
  background-repeat: no-repeat;
}
@media all and (max-width: 580px) {

  .content-compensacion .compesacion-header{
padding: 35px 30px;
  }

  .content-compensacion .compesacion-header span.ruta {
    color: #003967;
    font-size: 14px;
  }
  
  b.cargo {
    color: #0078c6;
  }
  
  .content-compensacion .compesacion-header span.volver {
    font-size: 14px;
  }
  
  .content-compensacion .compesacion-header span.volver img {
    display: block;
    margin-right: 10px;
    max-width: 14px;
  }


  .content-compensacion .formulario-compensacion .botones{
      flex-direction: column;
  }
  .content-compensacion .formulario-compensacion .botones button{
      margin: 10px 0;
  }
  .content-compensacion .formulario-compensacion span.datos {
      display: flex;
      flex-direction: column;
  }
  body{
      height: auto;
  }
}
