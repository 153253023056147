.content-nuevo {
  width: 100%;
  background-color: #ebebeb;
}
.content-nuevo .nuevo {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2% 0 5%;
  width: 100%;
}


@media all and (max-width: 1200px) {
  .content-nuevo .nuevo{
    max-width: 1024px;
  }
}

@media all and (max-width: 1024px) {
  .content-nuevo .nuevo{
    max-width: 800px;
  }
}

.content-nuevo .nuevo .titulo {
  color: #0078c6;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 20px;
  display: inline-block;
}

.content-nuevo .nuevo .formulario {
  background-color: #fff;
  padding: 40px 20px;
}

.content-nuevo .nuevo .formulario .sesion {
  color: #00195a;
  font-weight: 400;
  font-size: 16px;
}

.content-nuevo .nuevo .formulario .sesion b {
  color: #0078c6;
  font-weight: 600;
}

.content-nuevo .nuevo .formulario .item {
  color: #00195a;
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  padding-bottom: 5px;
}

.content-nuevo .nuevo .formulario .item span {
  color: #0078c6;
  font-weight: 600;
}

.content-nuevo .nuevo .formulario .reportara {
  display: flex;
}

.content-nuevo .nuevo .formulario .reportara .item {
  margin-right: 20px;
  display: block;
}

.content-nuevo .nuevo .formulario .reportara .content-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.content-nuevo .nuevo .formulario .reportara .content-input label {
  margin: 0 5px;
  color: #0078c6;
  font-size: 16px;
}
.datos-him {
  margin: 15px 0;
}

.content-datos {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}

.content-datos input {
  height: 45px;
  border: 1px solid #bababa;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
}
.content-datos {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}

.content-datos select {
  height: 45px;
  border: 1px solid #bababa;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.row {
  margin: 15px 0;
}
.content-datos textarea {
  /*margin: 10px 0;*/
  outline: none;
  border: 1px solid #bababa;
  padding: 5px 15px;
  border-radius: 5px;
}
.botones {
  display: flex;
  padding: 0 5px;
  margin: 15px 0;
}

.botones button {
  border: none;
  background-color: #ffff;
  padding: 10px 23px;
  border-radius: 5px;
  margin: 0 5px;
  font-weight: 700;
  outline: none;
}

div#mas_tarde {
  border: none;
  padding: 10px 23px;
  border-radius: 5px;
  margin: 0 5px;
  font-weight: 700;
  outline: none;
  background-color: #b1a547;
  color: #ffff;
  cursor: pointer;
  display: inline-block;
}

button#guardas {
  background-color: #006bb0;
  color: #fff;
}

button#mas_tarde {
  background-color: #b1a547;
  color: #ffff;
}
@media all and (max-width: 500px) { 
  .content-datos {
    padding: 10px 5px;
  }

  .content-nuevo .nuevo .formulario .sesion{
    font-size: 14px;
  }

  .content-nuevo .nuevo .formulario .item{
    font-size: 14px;
  }

  .botones{
    flex-direction: column;
  }

  .botones button{
    font-size: 14px;
    margin: 10px 0;
  }

}