/* body{
  background-image: url(../../../images/panel/back.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200vh;
} */
.content-candidatos{
  background-color: #ebebeb;
/*   background-image: url('../../../images/panel/back.png'); */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 24%;
  
}
.view-eyes{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.applicant-count{
  font-size: larger;
  color:  orange;
}
.content-candidatos .contenido {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 15px;
}

@media all and (max-width: 1200px) {
  .content-candidatos .contenido {
    max-width: 1024px;
  }
}

@media all and (max-width: 1024px) {
  .content-candidatos .contenido {
    max-width: 800px;
  }
}


.content-candidatos .contenido .content-table{
  position: relative;
  padding-top: 90px;
  overflow-y: hidden;
  width: 100%;
}

.content-candidatos .contenido .content-table.with-departament{
  padding-top: 150px;
}

div.table_candidatos_length {display: none;}
.dataTables_wrapper .dataTables_filter input{
  
background-color: rgba(255, 255, 255, 0.5);
  
outline: none;
}
.table_candidatos {
  border-collapse: separate;
  border-spacing: 0 15px !important;
  width: 100% !important;
  border: none !important;
}

.table_candidatos th {
  padding: 15px 10px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 5px 0;
  border: none;
  color: #003967;
  font-weight: 400;
  font-size: 14px;
}
.table_candidatos tr th, .table_candidatos tr td{
  border: 0px !important;
  font-size: 14px;
  vertical-align: middle !important;
  text-align: center;
}


.table_candidatos tr {
  border-spacing: 5em;
  border: none;
}

.table_candidatos td {
  background-color: #fff;
}
.table_candidatos td .puesto {
  font-weight: 700;
}
.table_candidatos .finalizado td {
  background-color: #d6d6d6;
}

.table_candidatos td {
  color: #0078c6;
  padding-right: 10px;
  padding-left: 10px;
}

.table_candidatos td .color-estado {
  width: 20px;
  height: 20px;
  display: flex;
  background-color: red;
  border-radius: 50%;
  margin-right: 14px;
  line-height: 1;
  text-align: center;
  color: #000;
  justify-content: center;
  align-items: center;
}
.table_candidatos .finalizado td .contet-director {
  display: flex;
  align-items: center;
}
.table_candidatos .pendiente td .contet-director {
  /* display: flex; */
  align-items: center;
}
.table_candidatos td .pausado {
  background-color: #b1a547;
  color: #b1a547;
}

.table_candidatos td .aprovado {
  background-color: #508e66;
  color: #508e66;
}

.table_candidatos td .rechazado {
  background-color: #b44949;
  color: #b44949;
}

.table_candidatos td .espera {
  background-color: #fff;
  border: 1px solid;
  color: #000;
}

.table_candidatos td .candidato.guardado,
.table_candidatos td .CANDIDATO.GUARDADO {
  color: #5d5c5c;
}

.table_candidatos  td .contratado,
.table_candidatos  td .CONTRATADO {
  color: #30894f;
}
.table_candidatos td .no.avanza,
.table_candidatos td .NO.AVANZA {
  color: #b44949;
}

.table_candidatos td .finalizado,
.table_candidatos td .FINALIZADO {
  color: #0078c6;
}

.table_candidatos td .candidato.resaltado,
.table_candidatos td .CANDIDATO.RESALTADO {
  color: #b1a547;
}


.table_candidatos td .estado_general .Pendiente,
.table_candidatos td .estado_general .PENDIENTE {
  color:#5d5c5c;
}
.table_candidatos td .estado_general .En.proceso,
.table_candidatos td .estado_general .EN.PROCESO {
  color: #30894f;
}
.table_candidatos td .estado_general .Rechazada,
.table_candidatos td .estado_general .RECHAZADA {
  color: #b44949;
}
.table_candidatos td .estado_general .Finalizada,
.table_candidatos td .estado_general .FINALIZADA {
  color: #0078c6;
}
.table_candidatos td .estado_general .Pausado,
.table_candidatos td .estado_general .PAUSADO {
  color: #b1a547;
}
.table_candidatos td .estado_general .Incompleta,
.table_candidatos td .estado_general .INCOMPLETA {
  color: #92491f;
}

.table_candidatos td .estado_general {
  text-transform: uppercase;
}



.content-filtrar {
  display: initial;
  align-items: center;
}
.content-filtrar-profile{
  display: initial;
  align-items: center;
}

.content-filtrar.destacado select {
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  border: none;
  padding: 5px;
  width: 243px;
  color: #8c8b8b;
  margin-left: 15px;
}

.content-filtrar.destacado {
  margin-top: 70px;
  right: 0;
  left:auto;
}

.content-filtrar.visto select {
  margin-left: 53px;
}
.content-filtrar.visto {
  margin-top: 65px;
}

.content-filtrar .filtrar-span {
  color: #0078c6;
  font-weight: 700;
  font-size: 16px;
}

.content-filtrar select {
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  border: none;
  padding: 5px;
  width: 243px;
  color: #8c8b8b;
  margin-left: 15px;
}
.content-filtrar.departament,
.content-filtrar.where_find{
  margin-top: 105px;
}
.content-filtrar.where_find{
  left: unset;
  right: 0;
}
.content-filtrar.profile_detail {
  margin-top: 16px;
    margin-left: 350px;
  
;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

/* vista */
.vista-candidato {
  background-color: #fff;
  padding: 22px 15px;
}
.vista-candidato .content-cerrar {display: flex;justify-content: flex-end;}

.vista-candidato .content-cerrar .cerraar {
  color: #bababa;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  margin-top: -10px;
  margin-bottom: 10px;
}

.vista-candidato .content-cerrar .cerraar b {
  font-size: 16px;
}

.vista-candidato .cabecera {
  border-bottom: 1px solid #0078c6;
  margin: 0;
}

.vista-candidato .cabecera span {
  color: #003967;
  font-size: 16px;
  font-weight: 500;
}
.vista-candidato .caja {
  padding: 10px 0;
}

.vista-candidato .datos {
  margin: 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.24);
}

.vista-candidato .caja span {
  color: #003967;
}

.vista-candidato .caja span b {
  color: #0078c6;
}
.vista-candidato .caja .curriculum {
  background-color: #8c8b8b;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.vista-candidato .caja .curriculum img {
  display: block;
  width: 100%;
  max-width: 25px;
  margin: 0 10px;
}

/* -------------------------------------------------------------------- */
/*
.content-formulario span.volver {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: #8c8b8b;
  font-size: 16px;
  padding-top: 20px;
  padding-right: 20px;
}

.ccontent-formulario-candidatos span.volver img {
  display: block;
  margin-right: 10px;
  max-width: 14px;

}
.content-formulario-candidatos span.volver a {
  text-decoration: none;
}

.content-formulario-candidatos .content-datos-candidato .content-linea .input-error input {
  background-color: transparent;
  cursor: default;
}

.content-formulario-candidatos .opciones .curriculum {
  background-color: #004c80;
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-formulario-candidatos .opciones .curriculum img{
  display: block;
  width: 100%;
  max-width: 18px;
  margin: 0px 12px 0 12px;
}  */

/* -------------------------------------------------------------------- */


.content-formulario-datos {
  background-color: #ebebeb;
  width: 100%;
}

.content-formulario-datos span.volver {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: #8c8b8b;
  font-size: 16px;
  padding-top: 20px;
  padding-right: 20px;
}

.content-formulario-datos span.volver img {
  display: block;
  margin-right: 10px;
  max-width: 14px;

}
.content-formulario-datos span.volver a {
  text-decoration: none;
}

.content-formulario-datos .formulario .form .content-datos {
  border-top: 1px solid #8c8b8b;
  border-bottom: 1px solid #8c8b8b;
  width: 100%;
  margin: 15px 0;
}

.content-formulario-datos .formulario {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3% 0px;
}

.content-formulario-datos .formulario .form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
}

.content-formulario-datos .formulario .form .last_job{
  color: #004c80;
}

.content-formulario-datos .formulario .form .section-selector{
  display: flex;
  padding: 10px 20px;
  width: 100%;
  justify-content: space-between;
}


.content-formulario-datos .formulario .form .section-selector #asignar {
  width: 50%;
  height: 45px;
  font-weight: 500;
  outline: none;
  border-radius: 5px;
  border: 1px solid #bababa;
  padding: 5px 15px;
}

.content-formulario-datos .formulario .form .section-selector #editar {
  border: none;
  background-color: #006bb0;
  color: #fff;
  padding: 10px 23px;
  margin: 0 5px;
  font-weight: 700;
  outline: none;
}

.content-formulario-datos .formulario .content-datos input {
  width: 100% !important;
  height: 45px;
  border: none;
  border-radius: 5px;
  margin: 5px 0 15px;
  outline: none;
  padding: 5px 15px;
  color: #5d5c5c;
}
.content-formulario-datos .formulario .content-datos .content-linea {
  display: flex;
  justify-content: space-between;
}
.content-formulario-datos .formulario .content-datos .content-linea .opciones {
  display: flex;
  flex-direction: row;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones .columna{
padding: 0 5px;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones button.marcar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 5px 40px 5px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 15px 0;
  outline: none;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones div.marcar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 5px 40px 5px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 15px 0;
  outline: none;
  cursor: pointer;
  text-align: center;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones div.marcar.marcado{
  color: #2cb5a5;
  border: 1px solid #2cb5a5;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones div.marcar img {
  position: absolute;
  top: 4px;
  right: 7px;
  width: 100%;
  max-width: 25px;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones div.resaltar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 8px 40px 8px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 15px 0 15px;
  outline: none;
  cursor: pointer;
  text-align: center;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones div.resaltar.resaltado {
  border: 1px solid #2cb5a5;
  color: #2cb5a5;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones div.resaltar img {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 100%;
  max-width: 22px;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones .mensajes {
  display: grid;
 }

.content-formulario-datos .formulario .content-datos .content-linea .opciones .span-error {
 color: red;
}
.content-formulario-datos .formulario .content-datos .content-linea .opciones button.marcar img {
  position: absolute;
  top: 4px;
  right: 7px;
  width: 100%;
  max-width: 25px;
}
.content-formulario-datos .formulario .content-datos .content-linea .opciones div.resaltar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 11px 35px 11px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 2px 0;
  outline: none;
  font-size: 16px;
}

.content-formulario-datos .formulario .content-datos .content-linea .opciones button.resaltar img {
  position: absolute;
  top: 4px;
  right: 7px;
  width: 100%;
  max-width: 25px;
}


.content-formulario-datos .content-datos .content-linea .input-error {
 width: 49%;
}

.content-formulario-datos .content-datos .content-linea .input-error input {
  /* background-color: ; */
  cursor: default;
}

.content-formulario-datos .opciones .curriculum {
  background-color: #004c80;
  border: none;
  width: 100%;
  margin: 2px 0;
  /* border-radius: 5px; */
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.content-formulario-datos .opciones .curriculum.reactivar{
  background-color: #d2d1d1;
  color: #003967;
}

.content-formulario-datos .opciones .curriculum.small{
  display: inline-block;
  max-width: 150px;
  padding: 5px 7px;
  text-align: center;
  font-size: 12px;
}
.content-formulario-datos .opciones .curriculum img{
  display: block;
  width: 100%;
  max-width: 18px;
  margin: 0px 12px 0 12px;
}


.content-formulario-datos .opciones .guardar-cambios {
  background-color: #4a9900;
  border: none;
  width: 100%;
  margin: 2px 0;
  /* border-radius: 5px; */
  padding: 12px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal_edit_applicant .modal-header ,
.modal_check_edit_applicant .modal-header {
  background-image: url('../../../images/menu/banner_modal.png');
  background-size: cover;
  height: 150px;
  background-repeat: no-repeat;
}

.modal_edit_applicant .content-edit {
  background-color: #ebebeb;
}

.modal_edit_applicant .titulo,
.modal_check_edit_applicant .titulo{
  text-align: center;
  color: #003967;
  font-weight: 600;
}

.modal_check_edit_applicant .titulo{
  font-size: 16px;
  margin: 40px 0;
}

.modal_edit_applicant .content-input{
  display: flex;
  /* flex-direction: column; */

}

.modal_edit_applicant .content-input label{
  font-weight: 700;
  color: #0078c6;
  margin: 5px 0 0;
  font-size: 13px;
}

.modal_edit_applicant .content-input input{
  width: 100% !important;
  height: 45px;
  border-radius: 5px;
  margin: 5px 0 5px;
  outline: none;
  border: none;
  padding: 5px 15px;
}
.modal_edit_applicant .content-input select{
  width: 100% !important;
  height: 45px;
  border-radius: 5px;
  margin: 5px 0 5px;
  outline: none;
  border: none;
  padding: 5px 15px;
}

.modal_edit_applicant .content-input .error-message{
 color: red;
 font-weight: 600;
 margin: 0 10px;
}


.modal_edit_applicant .content-buttons ,
.modal_check_edit_applicant .content-buttons{
  display: flex;
  justify-content: space-around;
}

.modal_edit_applicant .content-buttons button,
.modal_check_edit_applicant .content-buttons button  {
  border: none;
  background-color: #ffff;
  padding: 10px 23px;
  border-radius: 5px;
  margin: 0 5px;
  font-weight: 700;
  outline: none;
}

.modal_edit_applicant .content-buttons button#guardar,
.modal_check_edit_applicant .content-buttons button#guardar {
  background-color: #006bb0;
  color: #fff;
}

.modal_edit_applicant .content-buttons button#cancelar,
.modal_check_edit_applicant .content-buttons button#cancelar {
  background-color: #006bb0;
  color: #fff;
}

/* #accordion {} */

#accordion .card-header h5 {
  display: flex;
  justify-content: space-between;
}

#accordion .card-header {
  padding: 0;
  color: #f0f4f7;
  background-color: #0078c6;
}

#accordion .card-header h5 button.btn.btn-link {
  color: #f0f4f7;
  outline: none;
  text-decoration: none;
  box-shadow: none;
}

#accordion .card-header h5 .mas {
  padding: 2px 5px;
  font-size: 26px;
  cursor: pointer;
}
.barra-superior {
  display: flex;
  justify-content: space-between;
}

.barra-superior .left {
  display: flex;
}

.barra-superior .left .item {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.barra-superior .left .item span {
  color: #003967;
  font-weight: 700;
}

.barra-superior .left .item label {
  /* background-color: #fff; */
  width: fit-content;
  /* padding: 8px 18px; */
  color: #0078c6;
  font-size: 16px;
  font-weight: 700;
  margin: 15px 0;
  /* border: 1px solid #d2d1d1; */
}
.barra-superior .right button {
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  color: #5d5c5c;
  display: flex;
  padding: 6px 15px;
  outline: none;
  font-weight: 700;
}

.barra-superior .right button img {
  display: block;
  width: 100%;
  max-width: 26px;
  margin-left: 10px;
}
.collapse.show {
  background-color: #f0f4f7;
}
.content-feedback {
  display: flex;
  flex-direction: column;
}

.content-feedback span {
  margin: 10px 0;
}

.content-feedback #feedback {
  outline: none;
  border: 1px solid #bababa;
  padding: 15px;
  color: #0078c6;
  font-size: 16px;
  font-weight: 700;
}

.botones-vista button {
  padding: 12px 28px;
  margin: 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-weight: 700;
  outline: none;
  width: 248px;
  font-size: 13px;
  line-height: 1;
}
.botones-vista button:hover {
  opacity: 0.5;
}

.botones-vista button#aprobar {
  background-color: #508e66;
}
.botones-vista button#aceptar {
  background-color: #508e66;
}
.botones-vista button#pausar {
  background-color: #b1a547;
}
.botones-vista button#negar {
  background-color: #b44949;
}
.botones-vista button#no_acepta {
  background-color: #b44949;
}
.botones-vista button#decidir {
  background-color: #bababa;
}
.botones-vista {
  display: flex;
  margin: 18px 0;
  flex-wrap: wrap;
}

#no-acepto {
  text-align: center;
}

#no-acepto .titulo {
  font-size: 22px;
  line-height: 1;
  color: #0078c6;
}

#no-acepto .titulo span {
  color: #003967;
}

#no-acepto .form .content {
  display: flex;
  width: 100%;
  max-width: 230px;
  margin: 10px auto;
  flex-direction: column;
  align-items: baseline;
}
#no-acepto .form .content label {
  font-size: 12px;
  margin: 0 5px;
  color: #5d5c5c;
  font-weight: 400;
}

#no-acepto .form .content div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
}

#no-acepto .modal-footer {
  border: none;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

#no-acepto .modal-footer #guardar {
  background-color: #0078c6;
  color: #fff;
  padding: 5px 25px;
  border: none;
}

#no-acepto .modal-footer  #cancelar {
  background-color: #bababa;
  border: none;
}

#no-acepto .modal-body {
  padding: 30px 0 0;
}
.barra-superior .left .item input {
  border: 1px solid #bababa;
  outline: none;
  padding: 10px;
}
.content-feedback p {
  color: #0078c6;
  font-weight: 700;
  font-size: 16px;
}
.pestana {
  width: fit-content;
  background-color: #f0f4f7;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.pestana span {
  font-weight: 700;
  font-size: 16px;
  color: #8c8b8b;
}

.content-pestana {display: flex;}

.pestana.active {
  background-color: #fff;
}

.pestana.active span {
  color: #0078c6;
}

.barra-superior .right button.resaltado {
  border: none;
  color: #2cb5a5;
}

.vista-candidato .avanza {
  color: #30894f;
  font-weight: 600;
}

.vista-candidato .guardado {
  color: #b1a547;
  font-weight: 600;
}

.vista-candidato .no_avanza {
  color: #b44949;
  font-weight: 600;
}
img.ver {
  cursor: pointer;
}
.no-hover{

}

.content-formulario-datos .formulario .form .content-datos .content-linea .input-error label {
  font-weight: 700;
  color: #0078c6;
  margin: 14px 0 0;
  font-size: 13px;
}
.table_view_process{
  margin: 3rem auto
}
.table_view_process thead tr :last-child{
  text-align: center;
}
.table_view_process td{
  max-width: 300px;
}
.table_view_process td, .table_view_process th{
  margin: 0 30px;
}

@media all and (max-width: 1024px) {
  .content-formulario-datos .formulario{
    max-width: 800px !important;
    padding: 3% !important;
  }
}

@media all and (max-width: 892px) {
  
  .modal_edit_applicant .modal-header ,
  .modal_check_edit_applicant .modal-header {
    height: 72px;
  }
}


@media all and (max-width: 800px) {
  
  .pestana span{
      font-size: 12px;
  }
  .pestana{
      padding: 10px;
  }
  .barra-superior{
      flex-direction: column;
  }
}

@media all and (max-width: 755px) {
  .content-formulario-datos .formulario .form .content-datos{
      flex-direction: column;
  }
  .content-formulario-datos .formulario .form .content-datos .content-linea{
      flex-direction: column;
  }
  .content-formulario-datos .formulario .form .content-datos input{
      width: 100% !important;
  }
  .content-formulario-datos .formulario .form #asignar{
      width: 100%;
  }
  .content-formulario-datos .formulario .form .content-datos .content-linea .input-error{
    width: 100% !important;
  }
  .content-formulario-datos .formulario .form .content-datos .content-linea .opciones {
    flex-direction: column;
  }
}

@media all and (max-width: 700px) {
  .candidatos-search {
    top: 70px !important;
  }

  .content-filtrar.visto {
    margin-top: 35px !important;
  }
  table.table_view_process tbody tr td div#button_proceso{
    font-size: 0.8rem;
    text-align: center;
    line-height: inherit;
  }
}

@media all and (max-width: 500px) {
  .pestana span{
      font-size: 10px;
  }
  .pestana{
      padding: 5px;
  }
  .candidatos .vista-candidato .caja span{
      font-size: 14px;
  }
  .vista-candidato .content-cerrar .cerraar{
      font-size: 14px;
  }
  .barra-superior .left{
      flex-direction: column;
  }
  .barra-superior .left .item span{

    font-size: 12px;

  }
  .barra-superior .left .item label{
          
    padding: 5px 14px;
          
    font-size: 14px;
  }
  .barra-superior .right button{
      
    padding: 3px 10px;
          
    font-size: 14px;
  }
  .content-feedback #feedback{
      
    height: 200px;
  }
  .botones-vista button{
      
    font-size: 12px;
          
    height: 59px;
  }
  .content-time-line .time-line-header span.volver{
      margin: 0 0 10px;
  }
  .content-filtrar{
    display: flex;
  }

  
  /* .content-filtrar .filtrar-span{

  } */
  .content-filtrar select{
    width: 200px;
  }
  div.table_candidatos_filter{
    display: flex;
  }
}

@media(max-width: 575px) {
  #reactivar_proceso .modal-action{
    flex-direction: column !important;
    width: 100%;
  }

  #reactivar_proceso .modal-action button{
    width:  100%;
  }
}

.candidato_destacado{
  font-weight: 800 !important;
  color: #006bb0 !important;
}

.notes-count{
  background-color: #4a9900;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-left: 7px;
  color: #FFF !important;
}
.text-feedback {
  border-radius: 5px;
  padding: 10px;
  border: 2px solid #e5e5e5;
}