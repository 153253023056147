body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans' !important;
  font-size: 15px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.25!important;
}

.pagination .page-link {
  color: #666 !important;
  line-height: 20px;
}

.pagination .page-link option {
  padding: 0;
}

.pagination .page-link.no-hover {
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
