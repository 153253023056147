.contenido-principal {
  width: 100%;
  height: 100%;
  background-color: #ebebeb;
  padding-bottom: 6%;
}
.content-time-line {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  background-color: #ebebeb;
}
.danger{
  border: 2px solid red;
}
@media all and (max-width: 1200px) {
  .content-time-line {
    max-width: 1024px;
  }
}

@media all and (max-width: 1024px) {
  .content-time-line {
    max-width: 800px;
  }
  .time-line .time-line-absolute #paso_3.completo::before {
    width: 14%;
    left: 178px !important;
    top: 30%;
  }
  .time-line .time-line-absolute #paso_4.completo::before {
    width: 15% !important;
    left: 318px !important;
  }
  .time-line .time-line-absolute #paso_5.completo::before {
    width: 15% !important;
    left: 474px !important;
  }
  .time-line .time-line-absolute #paso_6.completo::before {
    width: 14% !important;
    left: 626px !important;
  }
  .time-line .time-line-absolute #paso_6.completo::after {
    width: 4% !important;
    left: 771px !important;
  }

  .time-line .time-line-absolute #paso_3.active::before {
    width: 14%;
    left: 178px !important;
    top: 30%;
  }
  .time-line .time-line-absolute #paso_4.active::before {
    width: 15% !important;
    left: 318px !important;
  }
  .time-line .time-line-absolute #paso_5.active::before {
    width: 15% !important;
    left: 474px !important;
  }
  .time-line .time-line-absolute #paso_6.active::before {
    width: 14% !important;
    left: 626px !important;
  }
  .time-line .time-line-absolute #paso_6.active::after {
    width: 4% !important;
    left: 771px !important;
  }
}

.content-time-line .time-line-header {
  display: flex;
  justify-content: space-between;
  /* margin: 3% 15px; */
  padding: 30px 15px 60px;
}

.content-time-line .time-line-header span.ruta {
  color: #003967;
  font-size: 16px;
}

b.cargo {
  color: #0078c6;
}

.content-time-line .time-line-header span.volver {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #8c8b8b;
  font-size: 16px;
}

.content-time-line .time-line-header span.volver img {
  display: block;
  margin-right: 10px;
  max-width: 14px;
}
.time-line {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  /* z-index: 99; */
  border-bottom: 3px solid #bababa;
}
.time-line .time-line-absolute {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: -20px;
  /* z-index: 99; */
}

.time-line .time-line-absolute .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -36px;
  /* border: 2px solid; */
  /* z-index: 99 !important; */
}
/*.time-line .time-line-absolute .item.completo,
.time-line .time-line-absolute .item.active {
  cursor: pointer;
}*/

#paso_1,
#paso_2 {
  cursor: pointer;
}

.time-line .time-line-absolute .item .numero {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #bababa;
  border: 3px solid #bababa;
  font-weight: 700;
}
.time-line .time-line-absolute .item .seccion {
  color: #bababa;
  font-weight: 700;
  font-size: 15px;
  margin-top: 10px;
  /* justify-content: center;
  align-items: center; */
}
.time-line .time-line-absolute .item.active .numero {
  color: #0078c6;
  border: 3px solid #0078c6;
  z-index: 10;
}
.time-line .time-line-absolute .item.active .numero {
  color: #0078c6;
  border: 3px solid #0078c6;
}
.time-line .time-line-absolute .item.active .seccion {
  color: #0078c6;
}
.time-line .time-line-absolute #paso_1.active::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 2%;
  position: absolute;
  left: 0;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_1.completo::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 2%;
  position: absolute;
  left: 0;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_2.active::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 14%;
  position: absolute;
  left: 53px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_2.completo::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 14%;
  position: absolute;
  left: 53px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_3.active::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 14%;
  position: absolute;
  left: 221px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_3.completo::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 14%;
  position: absolute;
  left: 221px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_4.active::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 17%;
  position: absolute;
  left: 387px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_4.completo::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 17%;
  position: absolute;
  left: 387px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_5.active::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 17%;
  position: absolute;
  left: 584px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_5.completo::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 17%;
  position: absolute;
  left: 584px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_6.active::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 16%;
  position: absolute;
  left: 774px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_6.completo::before {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 16%;
  position: absolute;
  left: 774px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_6.completo::after {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 3%;
  position: absolute;
  left: 970px;
  top: 30%;
  z-index: 1 !important;
}
.time-line .time-line-absolute #paso_6.active::after {
  content: "";
  height: 3px;
  background-color: #0078c6;
  width: 3%;
  position: absolute;
  left: 970px;
  top: 30%;
  z-index: 1 !important;
}

.form-busqueda {
  padding: 20px;
  background-color: #fff;
}

.form-candidatos {
  background-color: #fff;
  padding: 50px 40px;
}

.form-candidatos span.datos {
  font-size: 16px;
  color: #003967;
  font-weight: 400;
}

.form-candidatos span.datos b {
  color: #0078c6;
  font-size: 16px;
}

.form-candidatos hr.doble {
  border-top: 2px solid #0078c6;
}

.form-candidatos .descripcion {
  display: flex;
  flex-direction: column;
}

.form-candidatos .descripcion span {
  display: block;
  margin: 0px 0px 10px;
  font-size: 16px;
  color: #003967;
  font-weight: 400;
}

.form-candidatos .descripcion p {
  color: #0078c6;
  width: 100%;
  max-width: 460px;
  font-weight: 700;
  line-height: 1.2;
}
.form-candidatos .botones {
  display: flex;
}

.form-candidatos .botones button {
  padding: 12px 28px;
  margin: 0 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-weight: 700;
  outline: none;
}
.form-candidatos .botones button:hover {
  opacity: 0.5;
}

.form-candidatos .botones button#aprobar {
  background-color: #508e66;
}

.form-candidatos .botones button#pausar {
  background-color: #b1a547;
}

.form-candidatos .botones button#negar {
  background-color: #b44949;
}
.form-candidatos .botones button#copiar {
  background-color: #0078c6;
}
.timeline_seleccion .candidatos {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*margin: 7% 0;*/
}

.pestana {
  width: fit-content;
  background-color: #f0f4f7;
  display: flex;
  padding: 15px 20px 10px;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.pestana span {
  font-weight: 700;
  font-size: 14px;
  color: #8c8b8b;
}

.content-pestana {
  display: flex;
}

.pestana.active {
  background-color: #fff;
}

.pestana.active span {
  color: #0078c6;
}

.content-pestana-busqueda {
  display: flex;
}

.content-pestana-busqueda .pestana {
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  background-color: #f0f4f7;
  display: flex;
  padding: 15px 20px 10px;
  justify-content: center;
  margin: 0px 5px;
  cursor: pointer;
}

.busqueda.timeline_seleccion .table#candidatos {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100% !important;
  border: none;
}

.busqueda.timeline_seleccion .table#candidatos th {
  padding: 15px 10px;
  background-color: #f0f4f7;
  margin: 5px 0;
  border: none;
  color: #003967;
  font-weight: 400;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.busqueda.timeline_seleccion .table#candidatos tr {
  border-spacing: 5em;
  border: none;
}
.busqueda.timeline_seleccion #candidatos td {
  background-color: #f0f4f7;
}
.busqueda.timeline_seleccion #candidatos .pendiente td {
  background-color: #f0f4f7;
}

.busqueda.timeline_seleccion #candidatos .finalizado td {
  background-color: #d6d6d6;
}

.busqueda.timeline_seleccion #candidatos td {
  color: #0078c6;
  text-align: center;
  vertical-align: middle;
  border: none;
}
.busqueda.timeline_seleccion #candidatos .estado_general {
  text-transform: uppercase;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Pendiente,
.busqueda.timeline_seleccion #candidatos .estado_general .PENDIENTE {
  color: #5d5c5c;
}
.busqueda.timeline_seleccion #candidatos .estado_general .En.proceso,
.busqueda.timeline_seleccion #candidatos .estado_general .EN.PROCESO {
  color: #30894f;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Rechazada,
.busqueda.timeline_seleccion #candidatos .estado_general .RECHAZADA {
  color: #b44949;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Finalizada,
.busqueda.timeline_seleccion #candidatos .estado_general .FINALIZADA {
  color: #0078c6;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Pausado,
.busqueda.timeline_seleccion #candidatos .estado_general .PAUSADO {
  color: #b1a547;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Incompleta,
.busqueda.timeline_seleccion #candidatos .estado_general .INCOMPLETA {
  color: #92491f;
}
.busqueda.timeline_seleccion #candidatos .estado_general .No.avanza,
.busqueda.timeline_seleccion #candidatos .estado_general .NO.AVANZA {
  color: #b44949;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Aceptar.propuesta,
.busqueda.timeline_seleccion #candidatos .estado_general .ACEPTAR.PROPUESTA {
  color: #30894f;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Acepta.propuesta,
.busqueda.timeline_seleccion #candidatos .estado_general .ACEPTA.PROPUESTA {
  color: #30894f;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Avanza,
.busqueda.timeline_seleccion #candidatos .estado_general .AVANZA {
  color: #30894f;
}
.busqueda.timeline_seleccion #candidatos .estado_general .Contratado,
.busqueda.timeline_seleccion #candidatos .estado_general .CONTRATADO {
  color: #30894f;
}

.busqueda.timeline_seleccion #candidatos td .color-estado {
  width: 20px;
  height: 20px;
  display: flex;
  background-color: red;
  border-radius: 50%;
  margin-right: 14px;
  line-height: 1;
  text-align: center;
  color: #000;
  justify-content: center;
  align-items: center;
}
#candidatos .finalizado td .contet-director {
  /* display: flex; */
  align-items: center;
}
#candidatos .pendiente td .contet-director {
  /* display: flex; */
  align-items: center;
}
#candidatos td .pausado {
  background-color: #b1a547;
  color: #b1a547;
}

#candidatos td .aprovado {
  background-color: #508e66;
  color: #508e66;
}

#candidatos td .rechazado {
  background-color: #b44949;
  color: #b44949;
}

#candidatos td .espera {
  background-color: #fff;
  border: 1px solid;
  color: #000;
}
#candidatos td .pendiente {
  color: #5d5c5c;
}

#candidatos td .avanza {
  color: #30894f;
}

#candidatos td .rechazada {
  color: #b44949;
}

#candidatos td .pendiente-entrevista {
  color: #0078c6;
}

#candidatos td .candidato-guardado {
  color: #b1a547;
}
/*.content-filtrar {
  display: block;
  align-items: center;
  margin: 20px 0 10px;
  width: 400px;
}

.content-filtrar .filtrar-span {
  color: #0078c6;
  font-weight: 700;
  font-size: 16px;
}

.content-filtrar select {
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  border: none;
  padding: 5px;
  width: 243px;
  color: #8c8b8b;
  margin-left: 15px;
}*/
.candidatos .content-candidatos {
  background-color: #fff;
  padding: 0 5px 22px;
}

.content-time-line .content-candidatos .contenido {
  padding: 15px;
}

.table#candidatos-preselecionados {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100% !important;
  border: none;
}

.table#candidatos-preselecionados th {
  padding: 15px 10px;
  background-color: #f0f4f7;
  margin: 5px 0;
  border: none;
  color: #003967;
  font-weight: 400;
  font-size: 14px;
  font-weight: 700;
}

.table#candidatos-preselecionados tr {
  border-spacing: 5em;
  border: none;
}

#candidatos-preselecionados .pendiente td {
  background-color: #f0f4f7;
}

#candidatos-preselecionados .finalizado td {
  background-color: #d6d6d6;
}

#candidatos-preselecionados td {
  color: #0078c6;
}

#candidatos-preselecionados td .color-estado {
  width: 20px;
  height: 20px;
  display: flex;
  background-color: red;
  border-radius: 50%;
  margin-right: 14px;
  line-height: 1;
  text-align: center;
  color: #000;
  justify-content: center;
  align-items: center;
}
#candidatos-preselecionados .finalizado td .contet-director {
  /* display: flex; */
  align-items: center;
}
#candidatos-preselecionados .pendiente td .contet-director {
  /* display: flex; */
  align-items: center;
}
#candidatos-preselecionados td .pausado {
  background-color: #b1a547;
  color: #b1a547;
}

#candidatos-preselecionados td .aprovado {
  background-color: #508e66;
  color: #508e66;
}

#candidatos-preselecionados td .rechazado {
  background-color: #b44949;
  color: #b44949;
}

#candidatos-preselecionados td .espera {
  background-color: #fff;
  border: 1px solid;
  color: #000;
}
#candidatos-preselecionados td .pendiente {
  color: #5d5c5c;
}

#candidatos-preselecionados td .avanza {
  color: #30894f;
}

#candidatos-preselecionados td .rechazada {
  color: #b44949;
}

#candidatos-preselecionados td .pendiente-entrevista {
  color: #0078c6;
}

#candidatos-preselecionados td .candidato-guardado {
  color: #b1a547;
}
/*.content-filtrar {
  display: block;
  align-items: center;
  margin: 20px 0 10px;
}

.content-filtrar .filtrar-span {
  color: #0078c6;
  font-weight: 700;
  font-size: 16px;
}

.content-filtrar select {
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  border: none;
  padding: 5px;
  width: 243px;
  color: #8c8b8b;
  margin-left: 15px;
}*/
.candidatos-preselecionados .content-candidatos {
  background-color: #fff;
  /*padding: 22px 15px;*/
}

.table#candidatos-destacados {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100% !important;
  border: none;
}

.table#candidatos-destacados th {
  padding: 15px 10px;
  background-color: #f0f4f7;
  margin: 5px 0;
  border: none;
  color: #003967;
  font-weight: 400;
  font-size: 14px;
  font-weight: 700;
}

.table#candidatos-destacados tr {
  border-spacing: 5em;
  border: none;
}

#candidatos-destacados .pendiente td {
  background-color: #f0f4f7;
}

#candidatos-destacados .finalizado td {
  background-color: #d6d6d6;
}

#candidatos-destacados td {
  color: #0078c6;
}

#candidatos-destacados td .color-estado {
  width: 20px;
  height: 20px;
  display: flex;
  background-color: red;
  border-radius: 50%;
  margin-right: 14px;
  line-height: 1;
  text-align: center;
  color: #000;
  justify-content: center;
  align-items: center;
}
#candidatos-destacados .finalizado td .contet-director {
  /* display: flex; */
  align-items: center;
}
#candidatos-destacados .pendiente td .contet-director {
  /* display: flex; */
  align-items: center;
}
#candidatos-destacados td .pausado {
  background-color: #b1a547;
  color: #b1a547;
}

#candidatos-destacados td .aprovado {
  background-color: #508e66;
  color: #508e66;
}

#candidatos-destacados td .rechazado {
  background-color: #b44949;
  color: #b44949;
}

#candidatos-destacados td .espera {
  background-color: #fff;
  border: 1px solid;
  color: #000;
}
#candidatos-destacados td .pendiente {
  color: #5d5c5c;
}

#candidatos-destacados td .avanza {
  color: #30894f;
}

#candidatos-destacados td .rechazada {
  color: #b44949;
}

#candidatos-destacados td .pendiente-entrevista {
  color: #0078c6;
}

#candidatos-destacados td .candidato-guardado {
  color: #b1a547;
}
.timeline_seleccion .content-filtrar {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 10px;
}

.timeline_seleccion .content-filtrar.visto {
  margin-top: 70px;
}

.timeline_seleccion .content-filtrar .filtrar-span {
  color: #0078c6;
  font-weight: 700;
  font-size: 16px;
}

.timeline_seleccion .content-filtrar select {
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  width: 243px;
  color: #495057;
  margin-left: 15px;
  border-radius: 0.25rem;
  font-size: 15px;
}
.timeline_seleccion .content-filtrar.departament{
  margin-top: 120px;
}
.timeline_seleccion .content-filtrar.departament2{
  margin-top: 70px;
}
.timeline_seleccion .content-filtrar.where_find,
.timeline_seleccion .content-filtrar.where_find2{
  margin-top: 70px;
  right: 0;
  left: unset;
}

.timeline_seleccion .search {
  position: absolute;
  width: 300px !important;
  /* top: 20px; */
  right: 0;
  margin: 20px 5px 10px;
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.candidatos-destacados .content-candidatos {
  background-color: #fff;
  /*padding: 22px 15px;*/
}

/* vista candidato */

.vista-candidato {
  background-color: #fff;
  padding: 22px 15px;
}
.vista-candidato .content-cerrar {
  display: flex;
  justify-content: flex-end;
}

.vista-candidato .content-cerrar .cerraar {
  color: #bababa;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
}

.vista-candidato .content-cerrar .cerraar b {
  font-size: 14px;
  padding-left: 4px;
}

.candidatos .vista-candidato .cabecera {
  border-bottom: 1px solid #0078c6;
  margin: 0;
}

.candidatos .vista-candidato .cabecera span {
  color: #003967;
  font-size: 14px;
  font-weight: 500;
}
.candidatos .vista-candidato .caja {
  padding: 14px 0;
}

.candidatos .vista-candidato .caja a:hover {
  text-decoration: none;
}
.candidatos .vista-candidato .datos {
  margin: 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.24);
}

.candidatos .vista-candidato .caja span {
  color: #003967;
  font-size: 14px;
}

.candidatos .vista-candidato .caja span b {
  color: #0078c6;
}
.candidatos .vista-candidato .caja .curriculum {
  background-color: #8c8b8b;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.candidatos .vista-candidato .caja .curriculum img {
  display: block;
  width: 100%;
  max-width: 18px;
  margin: 0px 12px 0 0;
}

div.curriculum {
  background-color: #8c8b8b;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  margin: 15px 0 0;
  font-weight: 500;
  outline: none;
  line-height: 36px;
  cursor: pointer;
  width: 150px;
}

.filename {
  padding-top: 5px;
  position: absolute;
  font-size: 12px;
}
/*
.barra-superior .right .notas {
  background-color: #0078c6;
  border: none;
  border-radius: 5px;
  padding: 4px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-decoration: none;
}*/
.barra-superior .right .notas {
  background-color: #0078c6;
  border: none;
  border-radius: 5px;
  padding: 4px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  /*display: flex;*/
  width: 180px;
  align-items: center;
  text-decoration: none;
}
.barra-superior .right a {
  text-decoration: none;
}
.barra-superior .right.discretion{
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
}
.reserva {
  margin-top: 35px;
}

.barra-superior .right .notas img {
  width: 100%;
  max-width: 15px;
  font-weight: 400;
  margin: 0 12px 0 0;
}

.barra-superior .right .span-error {
  color: red;
}

.barra-superior .right .mensajes {
  display: grid;
}

/* #accordion {} */

#accordion .card-header h5 {
  display: flex;
  justify-content: space-between;
}

#accordion .card-header {
  padding: 0;
  color: #f0f4f7;
  background-color: #0078c6;
}

#accordion .card-header h5 button.btn.btn-link {
  color: #f0f4f7;
  outline: none;
  text-decoration: none;
  box-shadow: none;
  font-size: 14px;
}

#accordion .card-header h5 .mas {
  padding: 2px 5px;
  font-size: 26px;
  cursor: pointer;
}
.barra-superior {
  display: flex;
  justify-content: space-between;
}

.barra-superior .left {
  display: flex;
}

.barra-superior .left .item {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.barra-superior .left .item span {
  color: #003967;
  font-weight: 700;
  font-size: 14px;
}
.discretion .reserva span {
  color: #003967;
  font-weight: 700;
  font-size: 14px;
}

.barra-superior .left .item label {
  background-color: #fff;
  width: fit-content;
  padding: 8px 18px;
  color: #0078c6;
  font-size: 14px;
  font-weight: 700;
  margin: 15px 0;
  border: 1px solid #d2d1d1;
  min-width: 80px;
  min-height: 42px;
  line-height: 1.75;
}
.barra-superior .right button {
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  color: #5d5c5c;
  display: flex;
  padding: 6px 15px;
  outline: none;
  font-weight: 700;
  align-items: center;
  line-height: 28px;
  margin-bottom: 10px;
}

.barra-superior .right button img {
  display: block;
  width: 100%;
  max-width: 24px;
  margin-left: 10px;
}
.barra-superior .right button .checkbox {
  display: block;
  max-width: 24px;
  margin-left: 10px;
}

.collapse.show {
  background-color: #f0f4f7;
}
.content-feedback {
  display: flex;
  flex-direction: column;
}

.content-feedback span {
  margin: 10px 0;
  font-size: 14px;
}

.content-feedback textarea {
  outline: none;
  border: 1px solid #bababa;
  padding: 15px;
  color: #0078c6;
  font-size: 16px;
  font-weight: 700;
}

.content-complete-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-complete-information .line {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content-complete-information .line .item {
  width: 49%;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.content-complete-information .line .item .input {
  outline: none;
  border: 1px solid #bababa;
  padding: 8px 15px;
  color: #0078c6;
  font-size: 14px;
  font-weight: 700;
  margin: 5px 0;
}

.content-complete-information .line .item .span-error {
  color: #c60000;
  font-size: 14px;
  font-weight: 500;
}

.botones-vista button {
  padding: 12px 28px;
  margin: 5px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-weight: 700;
  outline: none;
  width: 248px;
  font-size: 13px;
  line-height: 1;
}

.botones-vista button:hover {
  opacity: 0.5;
}
.botones-vista button.aprobar,
button.aprobar,
.botones-vista button#aprobar {
  background-color: #508e66;
  color: #fff;
}
.botones-vista button.aceptar,
.botones-vista button#aceptar {
  background-color: #508e66;
}
.botones-vista button.pausar,
.botones-vista button#pausar {
  background-color: #b1a547;
}
.botones-vista button.negar,
.botones-vista button#negar {
  background-color: #b44949;
}
.botones-vista button.no_acepta,
.botones-vista button#no_acepta {
  background-color: #b44949;
}
.botones-vista button.decidir,
.botones-vista button#decidir {
  background-color: #bababa;
}
.botones-vista button#aprobar_1 {
  background-color: #508e66;
}
.botones-vista button#aprobar_reserva {
  background-color: #6cc58b;
}
.botones-vista button#aceptar_1 {
  background-color: #508e66;
}
.botones-vista button#pausar_1 {
  background-color: #b1a547;
}
.botones-vista button#negar_1 {
  background-color: #b44949;
}
.botones-vista button#database {
  background-color: #006bb0;
}
.botones-vista button#decidir_1 {
  background-color: #bababa;
}
.botones-vista {
  display: flex;
  margin: 18px 0;
  flex-wrap: wrap;
}

/*********Edit modules classes for EditDate*********/
.edit-button-date {
  pointer-events: all;
  display: inline !important;
  justify-content: end;
  margin: 0;
  margin-left: auto;
}

.edit-button-date button:hover {
  opacity: 0.5;
}

.edit-button-date button {
  background-color: #003967;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-weight: 700;
  outline: none;
  width: 60px;
  font-size: 13px;
  line-height: 1;
}

.edit-button-date img {
  max-width: 20px;
  margin: -4px auto auto 5px;
}

/*********Edit modules classes*********/
.edit-button {
  pointer-events: all;
  display: inline !important;
  justify-content: end;
  float: right;
  margin: 0;
  margin-left: auto;
}

.edit-button button:hover {
  opacity: 0.5;
}

.edit-button button {
  background-color: #003967;
  padding: 12px 28px;
  margin: 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-weight: 700;
  outline: none;
  width: 248px;
  font-size: 13px;
  line-height: 1;
}

.edit-button img {
  max-width: 20px;
  margin: -5px auto auto 5px;
}

.edit-modal-content {
  text-align: center;
}

.edit-modal-content p {
  font-weight: bold;
}

#no-acepto {
  text-align: center;
}

#no-acepto .titulo {
  font-size: 22px;
  line-height: 1;
  color: #0078c6;
}

#no-acepto .titulo span {
  color: #003967;
}

#no-acepto form .content {
  display: flex;
  width: 100%;
  max-width: 230px;
  margin: 10px auto;
  flex-direction: column;
  align-items: baseline;
}
#no-acepto form .content label {
  text-align: initial;
  font-size: 12px;
  margin: 0 5px;
  color: #5d5c5c;
  font-weight: 400;
}

#no-acepto form .content div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
}

#no-acepto .modal-footer {
  border: none;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

#no-acepto .modal-footer #guardar {
  background-color: #0078c6;
  color: #fff;
  padding: 5px 25px;
  border: none;
}

#no-acepto .modal-footer #cancelar {
  background-color: #bababa;
  border: none;
}

#no-acepto .modal-body {
  padding: 30px 0 0;
}
.barra-superior .left .item input {
  border: 1px solid #bababa;
  outline: none;
  padding: 10px;
}
.content-feedback p {
  color: #0078c6;
  font-weight: 700;
  font-size: 16px;
}
.busqueda.timeline_seleccion {
  /*background-color: #ffff;*/
  /* padding: 35px 0 0px 0; */
  margin: 10px 0;
  position: relative;
}

.busqueda .linea {
  display: flex;
  flex-direction: column;
}

.busqueda .linea select {
  margin: 10px 0 0;
  height: 45px;
  border: 1px solid #bababa;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.busqueda .linea input {
  margin: 10px 0 0;
  height: 45px;
  border: 1px solid #bababa;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.busqueda .datos {
  color: #003967;
}

.busqueda .datos b {
  color: #0078c6;
}

hr.doble {
  border-top: 2px solid #0078c6;
}

/* .descripcion {} */

.descripcion span {
  color: #003967;
}

.descripcion p {
  color: #0078c6;
  /* width: 44%; */
  line-height: 1;
  margin: 15px 0;
  font-weight: 700;
}

.botones {
  display: block;
}

.botones button#copiar {
  padding: 10px 20px;
  color: #fff;
  background-color: #0078c6;
  border: none;
  border-radius: 5px;
  outline: none;
}
.botones button#finalizar {
  padding: 10px 20px;
  color: #fff;
  background-color: #c60000;
  border: none;
  border-radius: 5px;
  outline: none;
}
.botones button#pausar {
  padding: 10px 20px;
  color: #fff;
  background-color: #c5a826;
  border: none;
  border-radius: 5px;
  outline: none;
}
.botones button#guardar {
  padding: 10px 20px;
  color: #fff;
  background-color: #0078c6;
  border: none;
  border-radius: 5px;
  outline: none;
}
.botones button#cancelar {
  padding: 10px 20px;
  color: #fff;
  background-color: #e93e38;
  border: none;
  border-radius: 5px;
  outline: none;
}
.botones button#aprobar {
  padding: 10px 20px;
  color: #fff;
  background-color: #34982b;
  border: none;
  border-radius: 5px;
  outline: none;
}

.time-line .time-line-absolute .item.completo .numero {
  color: #fff;
  background-color: #0078c6;
  border: 3px solid #0078c6;
}
.time-line .time-line-absolute .item.completo .seccion {
  color: #0078c6;
}
.content-btn-destacados {
  display: flex;
  justify-content: flex-end; /* Propiedad cambiada de end a flex-end */
}

.boton-copy-link {
  background-color: #0078c6;
}

.content-btn-destacados button.btn-destacados,
.content-btn-destacados button.btn-rechazado,
.content-btn-destacados button.btn-database {
  background-color: #508e66;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: 15px 10px;
  outline: none;
}

.content-btn-destacados button.btn-database {
  background-color: #006bb0;
}
.content-btn-destacados button.btn-rechazado {
  background-color: #c60000;
}

.content-btn-destacados button.btn-destacados {
  background-color: #508e66;
}

.content-btn-destacados button[disabled] {
  opacity: 0.7;
  cursor: no-drop;
}

.botones-vista button[disabled] {
  opacity: 0.7 !important;
  cursor: no-drop !important;
}
.content-time-line .time-line-header span.volver a {
  color: #8c8b8b;
  text-decoration: none;
}
.busqueda span.editar {
  position: absolute;
  right: 40px;
  /* top: 55px; */
  display: flex;
  align-items: center;
  color: #bababa;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
}

.busqueda span.editar img {
  width: 100%;
  max-width: 15px;
  margin-left: 5px;
}

.barra-superior .right button.outstanding,
.barra-superior .right button.highlighted {
  color: #2cb5a5;
  /* border: none; */
}

.barra-superior .right .reserva button.advanceWithiscretion,
.barra-superior .right .reserva button.discretion {
  color: blue;
  /* border: none; */
}


.disabled-accordion {
  pointer-events: none;
  /*opacity: 0.8;*/
}

#feedback {
  pointer-events: auto;
  margin-bottom: 50px;
}
.content-feedback #feedback-psicotecnica .input-error,
.content-feedback #feedback.input-error {
  border: 1px red solid;
}

.content-feedback .span-error {
  color: red;
}

.disabled .content-feedback #feedback-psicotecnica,
.disabled .content-feedback #feedback,
.disabled .content-feedback #feedback-medica {
  border: none;
  background: transparent;
  font-size: 14px;
}
.disabled .botones-vista {
  display: none;
}

.disabled .estado {
  display: inline;
  padding: 10px 0;
}

.disabled .estado .aprobado {
  color: #30894f;
}
.disabled .estado .pausado {
  color: #b1a547;
}
.disabled .estado .negado {
  color: #b44949;
}
.disabled .estado .tarde {
  color: #bababa;
}
.busqueda.timeline_seleccion .content-busqueda {
  background: none;
  padding-bottom: 1%;
  background-color: #fff;
}

.busqueda.timeline_seleccion .content-candidatos {
  background: none;
}
.barra-superior .left .item .fecha.module {
  background-color: #fff;
  color: #000;
  padding: 10px;
  margin: 7px 0;
  font-size: 15px;
  text-align: center;
}

.fade {
  transition: opacity 0.15s linear !important;
}

/* Css de desplegable base de datos*/
 .bsPrefix {
    display: flex;
    /* margin-right: -15px; */
    /* margin-left: -15px; */
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    width: 100%;
}


@media all and (max-width: 1000px) {
  .form-busqueda {
    padding: 50px 20px 20px 20px;
  }

  .time-line {
    max-width: 800px;
    margin: 75px auto;
  }
  .time-line .time-line-absolute #paso_3.completo::before {
    left: 180px;
  }
  .time-line .time-line-absolute #paso_3.active::before {
    left: 180px;
  }
  .time-line .time-line-absolute #paso_4.completo::before {
    left: 308px;
  }
  .time-line .time-line-absolute #paso_4.active::before {
    left: 308px;
  }
  .time-line .time-line-absolute #paso_5.completo::before {
    left: 463px;
  }
  .time-line .time-line-absolute #paso_5.active::before {
    left: 463px;
  }
  .time-line .time-line-absolute #paso_6.active::before {
    width: 14%;
    left: 621px;
  }
  .time-line .time-line-absolute #paso_6.completo::before {
    width: 14%;
    left: 621px;
  }
}

@media all and (max-width: 800px) {
  .time-line {
    max-width: 500px;
    margin: 75px auto;
  }
  .time-line .time-line-absolute .item .seccion {
    font-size: 12px;
  }

  .content-pestana-busqueda {
    max-width: 500px;
    margin: 0 auto;
  }

  .candidatos {
    max-width: 500px;
    margin: 2% auto;
  }
  .pestana span {
    font-size: 12px;
  }
  .pestana {
    padding: 10px;
  }
  .barra-superior {
    flex-direction: column;
  }
  .time-line .time-line-absolute #paso_2.completo::before {
    width: 8% !important;
    left: 45px !important;
  }
  .time-line .time-line-absolute #paso_3.completo::before {
    width: 9% !important;
    left: 122px !important;
    top: 30%;
  }
  .time-line .time-line-absolute #paso_4.completo::before {
    width: 15% !important;
    left: 203px !important;
  }
  .time-line .time-line-absolute #paso_5.completo::before {
    width: 12% !important;
    left: 298px !important;
  }
  .time-line .time-line-absolute #paso_6.completo::before {
    width: 10% !important;
    left: 395px !important;
  }
  .time-line .time-line-absolute #paso_6.completo::after {
    width: 4% !important;
    left: 480px !important;
  }

  .time-line .time-line-absolute #paso_2.active::before {
    width: 8% !important;
    left: 45px !important;
  }
  .time-line .time-line-absolute #paso_3.active::before {
    width: 9% !important;
    left: 122px !important;
    top: 30%;
  }
  .time-line .time-line-absolute #paso_4.active::before {
    width: 15% !important;
    left: 203px !important;
  }
  .time-line .time-line-absolute #paso_5.active::before {
    width: 12% !important;
    left: 298px !important;
  }
  .time-line .time-line-absolute #paso_6.active::before {
    width: 10% !important;
    left: 395px !important;
  }
  .time-line .time-line-absolute #paso_6.active::after {
    width: 4% !important;
    left: 480px !important;
  }
}

@media all and (max-width: 700px) {
  .timeline_seleccion .content-filtrar.visto {
    margin-top: 45px !important;
  }
  .timeline_seleccion .search {
    top: 90px !important;
  }
}

@media all and (max-width: 500px) {
  .content-pestana-busqueda {
    margin-top: 160px;
  }

  .busqueda.timeline_seleccion {
    margin: 15px 0 0;
  }

  .time-line {
    max-width: 300px;
    margin: 0 auto;
    border-bottom: transparent;
  }
  .time-line .time-line-absolute .item .seccion {
    font-size: 11px;
  }
  .candidatos {
    /* max-width: 300px; */
    margin: 7% auto;
  }
  .pestana span {
    font-size: 10px;
  }
  .pestana {
    padding: 5px;
  }
  .time-line .time-line-absolute {
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: end;
  }
  .time-line .time-line-absolute .item {
    margin: 5px 0;
    width: 95px;
  }
  .content-time-line .time-line-header {
    flex-direction: column-reverse;
  }
  .content-time-line {
    max-width: 400px;
  }
  .candidatos .vista-candidato .caja span {
    font-size: 14px;
  }
  .vista-candidato .content-cerrar .cerraar {
    font-size: 14px;
  }
  .barra-superior .left {
    flex-direction: column;
  }

  .busqueda span.editar {
    right: 20px;
    top: 20px;
  }

  .barra-superior .left .item span {
    font-size: 12px;
  }
  .barra-superior .left .item label {
    padding: 5px 14px;

    font-size: 14px;
  }
  .barra-superior .right button {
    padding: 3px 10px;

    font-size: 14px;
  }
  .content-feedback #feedback {
    height: 200px;
  }
  .botones-vista button {
    font-size: 12px;

    height: 59px;
  }
  .content-time-line .time-line-header span.volver {
    margin: 0 0 10px;
  }

  .time-line .time-line-absolute #paso_2.completo::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_2.active::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_1.completo::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_1.active::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_3.completo::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_3.active::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_4.completo::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_4.active::before {
    width: 0%;
    height: 0px;
  }

  .time-line .time-line-absolute #paso_5.completo::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_5.active::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_6.active::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_6.completo::before {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_6.completo::after {
    width: 0%;
    height: 0px;
  }
  .time-line .time-line-absolute #paso_6.active::after {
    width: 0%;
    height: 0px;
  }
}


.content-busqueda .contenido .content-table{
  padding-top: 130px !important;
}


.publicar-busqueda-container{
  justify-content: space-evenly;
}