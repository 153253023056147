.banner{
  background-image: url('../../../images/header/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.banner .content-banner {max-width: 1200px;margin: 0 auto;width: 100%;height: 193px;display: flex;justify-content: space-between;align-items: center;}

.banner .content-banner .left {
  display: flex;
  align-items: center;
}

.banner .content-banner .left img {
  display: block;
  width: 180px;
}

.banner .content-banner .left .hr {
  color: #fff;
  font-size: 40px;
  line-height: 1;
  margin-left: 15px;
  font-weight: 300;
}

.banner .content-banner .left .titulo {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.banner .content-banner .right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner .content-banner .right .nombre {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 1;
  display: block;
  margin-right: 30px;
  text-align: right;
}
.banner .content-banner .right .position{
  display: block;
  margin-top: 5px;
  font-size: 14px;
  color: #7fc4fd;
}
.banner .content-banner .right img {
  width: 100%;
  max-width: 33px;
}
.menu{
  background-image: url('../../../images/menu/back-menu.png');
  width: 100%;
  position: absolute;
  top: -56px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60px;
  display: flex;
}
.relative-menu {
  position: relative;
  width: 100%;
}

.menu .content-menu {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.menu .content-menu .lista {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.menu .content-menu .lista li {
  color: #fff;
  margin-right: 5%;
  font-size: 16px;
  font-weight: 400;
}
.menu .content-menu .lista li a {color: #fff;text-decoration: none;}

.menu .content-menu .lista li a.is-active{
  font-weight: 600;
}

.hamburger {
  display: none !important;
  padding: 0 !important;
  padding-right: 5px !important;
  outline: none !important;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  height: 3px !important;
  background-color: #fff !important;
  width: 34px !important;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: #fff !important;
}



#destacar .modal-header,
#save_for_later .modal-header,
#destacar-HIM-Director .modal-header,
#rechazar .modal-header,
#errorModal .modal-header {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}

#destacar .modal-footer,
#save_for_later .modal-footer,
#destacar-HIM-Director .modal-footer,
#rechazar .modal-footer,
#errorModal .modal-footer {
  justify-content: flex-end;
}

#destacar .modal-footer button,
#save_for_later .modal-footer button,
#destacar-HIM-Director .modal-footer button,
#rechazar .modal-footer button,
#errorModal .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}
#destacar span.texto-modal,
#save_for_later span.texto-modal,
#destacar-HIM-Director span.texto-modal,
#rechazar span.texto-modal,
#errorModal span.texto-modal {
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}

#destacar .modal-body,
#save_for_later .modal-body,
#destacar-HIM-Director .modal-body,
#rechazar .modal-body,
#errorModal .modal-body  {
  display: flex;
}
#destacar .modal-footer button#cancel,
#save_for_later .modal-footer button#cancel,
#destacar-HIM-Director .modal-footer button#cancel,
#rechazar .modal-footer button#cancel
#errorModal .modal-footer button#cancel {
  background-color: #bababa;
  border: none;
}

#general .modal-header {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}

.modal-edit-header {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
}

.modal-edit .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}

#general .modal-footer {
  justify-content: flex-end;
}

#general .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}
#general span.texto-general {
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}

#general .modal-body {
  display: flex;
}
#general .modal-footer button#general {
  background-color: #bababa;
  border: none;
}

#aprobar-busqueda .modal-header {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}
#aprobar-busqueda .modal-footer {
  justify-content: flex-end;
}

#aprobar-busqueda .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}
#aprobar-busqueda span{
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}

#aprobar-busqueda .modal-body {
  display: flex;
}
#general .modal-footer button#general {
  background-color: #bababa;
  border: none;
}
#negar-busqueda .modal-header {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}
#negar-busqueda .modal-footer {
  justify-content: flex-end;
}

#negar-busqueda .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}
#negar-busqueda span{
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}

#negar-busqueda .modal-body {
  display: flex;
}



#avanza-busqueda-1 .modal-header,
#avanza-busqueda-2 .modal-header,
#avanza-busqueda-3 .modal-header,
#avanza-busqueda-4 .modal-header,
#avanza-busqueda-5 .modal-header,
#avanza-busqueda-6 .modal-header,
#avanza-busqueda-8 .modal-header {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}
#avanza-busqueda-1 .modal-footer,
#avanza-busqueda-2 .modal-footer,
#avanza-busqueda-3 .modal-footer,
#avanza-busqueda-4 .modal-footer,
#avanza-busqueda-5 .modal-footer,
#avanza-busqueda-6 .modal-footer,
#avanza-busqueda-8 .modal-footer {
  justify-content: flex-end;
}

#avanza-busqueda-1 .modal-footer button,
#avanza-busqueda-2 .modal-footer button,
#avanza-busqueda-3 .modal-footer button,
#avanza-busqueda-4 .modal-footer button,
#avanza-busqueda-5 .modal-footer button,
#avanza-busqueda-6 .modal-footer button,
#avanza-busqueda-8 .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}

#avanza-busqueda-1 span,
#avanza-busqueda-2 span,
#avanza-busqueda-3 span,
#avanza-busqueda-4 span,
#avanza-busqueda-5 span,
#avanza-busqueda-6 span,
#avanza-busqueda-8 span {
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}


#avanza-busqueda-1 .modal-body,
#avanza-busqueda-2 .modal-body,
#avanza-busqueda-3 .modal-body,
#avanza-busqueda-4 .modal-body,
#avanza-busqueda-5 .modal-body,
#avanza-busqueda-6 .modal-body,
#avanza-busqueda-8 .modal-body {
  display: flex;
}


#database-busqueda-1 .modal-header{
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}

#database-busqueda-1 .modal-footer{
  justify-content: flex-end;
}

#database-busqueda-1 .modal-footer button{
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}

#database-busqueda-1 span{
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}


#database-busqueda-1 .modal-body{
  display: flex;
}

#guardar-busqueda-1 .modal-header,
#guardar-busqueda-2 .modal-header,
#guardar-busqueda-3 .modal-header,
#guardar-busqueda-4 .modal-header,
#guardar-busqueda-5 .modal-header,
#guardar-busqueda-6 .modal-header  {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}
#guardar-busqueda-1 .modal-footer,
#guardar-busqueda-2 .modal-footer,
#guardar-busqueda-3 .modal-footer,
#guardar-busqueda-4 .modal-footer,
#guardar-busqueda-5 .modal-footer,
#guardar-busqueda-6 .modal-footer {
  justify-content: flex-end;
}

#guardar-busqueda-1 .modal-footer button,
#guardar-busqueda-2 .modal-footer button,
#guardar-busqueda-3 .modal-footer button,
#guardar-busqueda-4 .modal-footer button,
#guardar-busqueda-5 .modal-footer button,
#guardar-busqueda-6 .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}

#guardar-busqueda-1 span,
#guardar-busqueda-2 span,
#guardar-busqueda-3 span,
#guardar-busqueda-4 span,
#guardar-busqueda-5 span,
#guardar-busqueda-6 span{
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}

#guardar-busqueda-1 .modal-body,
#guardar-busqueda-2 .modal-body,
#guardar-busqueda-3 .modal-body,
#guardar-busqueda-4 .modal-body,
#guardar-busqueda-5 .modal-body,
#guardar-busqueda-6 .modal-body {
  display: flex;
}


#no-avanza-busqueda-1 .modal-header,
#no-avanza-busqueda-2 .modal-header,
#no-avanza-busqueda-3 .modal-header,
#no-avanza-busqueda-4 .modal-header,
#no-avanza-busqueda-5 .modal-header,
#no-avanza-busqueda-6 .modal-header  {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}

#no-avanza-busqueda-1 .modal-footer,
#no-avanza-busqueda-2 .modal-footer,
#no-avanza-busqueda-3 .modal-footer,
#no-avanza-busqueda-4 .modal-footer,
#no-avanza-busqueda-5 .modal-footer,
#no-avanza-busqueda-6 .modal-footer {
  justify-content: flex-end;
}

#no-avanza-busqueda-1 .modal-footer button,
#no-avanza-busqueda-2 .modal-footer button,
#no-avanza-busqueda-3 .modal-footer button,
#no-avanza-busqueda-4 .modal-footer button,
#no-avanza-busqueda-5 .modal-footer button,
#no-avanza-busqueda-6 .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}
#no-avanza-busqueda-1 span,
#no-avanza-busqueda-2 span,
#no-avanza-busqueda-3 span,
#no-avanza-busqueda-4 span,
#no-avanza-busqueda-5 span,
#no-avanza-busqueda-6 span {
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}

#no-avanza-busqueda-1 .modal-body,
#no-avanza-busqueda-2 .modal-body,
#no-avanza-busqueda-3 .modal-body,
#no-avanza-busqueda-4 .modal-body,
#no-avanza-busqueda-5 .modal-body,
#no-avanza-busqueda-6 .modal-body {
  display: flex;
}


#decidir-busqueda-1 .modal-header,
#decidir-busqueda-2 .modal-header,
#decidir-busqueda-3 .modal-header,
#decidir-busqueda-4 .modal-header,
#decidir-busqueda-5 .modal-header,
#decidir-busqueda-6 .modal-header {
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}
#decidir-busqueda-1 .modal-footer,
#decidir-busqueda-2 .modal-footer,
#decidir-busqueda-3 .modal-footer,
#decidir-busqueda-4 .modal-footer,
#decidir-busqueda-5 .modal-footer,
#decidir-busqueda-6 .modal-footer {
  justify-content: flex-end;
}

#decidir-busqueda-1 .modal-footer button,
#decidir-busqueda-2 .modal-footer button,
#decidir-busqueda-3 .modal-footer button,
#decidir-busqueda-4 .modal-footer button,
#decidir-busqueda-5 .modal-footer button,
#decidir-busqueda-6 .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}
#decidir-busqueda-1 span,
#decidir-busqueda-2 span,
#decidir-busqueda-3 span,
#decidir-busqueda-4 span,
#decidir-busqueda-5 span,
#decidir-busqueda-6 span {
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}

#decidir-busqueda-1 .modal-body,
#decidir-busqueda-2 .modal-body,
#decidir-busqueda-3 .modal-body,
#decidir-busqueda-4 .modal-body,
#decidir-busqueda-5 .modal-body,
#decidir-busqueda-6 .modal-body {
  display: flex;
}

/* ........................................... */

#pausar-reanudar-busqueda .modal-header,
#guardar-cambios-busqueda .modal-header,
#cancelar-cambios-busqueda .modal-header,
#finalizar-busqueda .modal-header,
#asignar-cambio-otra-busqueda .modal-header,
#reactivar_proceso  .modal-header{
  background-image: url('../../../images/menu/banner_modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 104px;
}
#pausar-reanudar-busqueda .modal-footer,
#guardar-cambios-busqueda .modal-footer,
#cancelar-cambios-busqueda .modal-footer,
#finalizar-busqueda .modal-footer,
#asignar-cambio-otra-busqueda .modal-footer,
#reactivar_proceso .modal-footer {
  justify-content: flex-end;
}

#pausar-reanudar-busqueda .modal-footer button,
#guardar-cambios-busqueda .modal-footer button,
#cancelar-cambios-busqueda .modal-footer button,
#finalizar-busqueda .modal-footer button,
#asignar-cambio-otra-busqueda .modal-footer button,
#reactivar_proceso .modal-footer button {
  background-color: #0078c6;
  color: #fff;
  font-weight: 700;
  border: none;
}
#reactivar_proceso .modal-footer button.btn-balanz-secondary {
  background-color: transparent;
  color: #0078c6;
  font-weight: 700;
  border: 1px solid #0078c6;
}
#pausar-reanudar-busqueda span,
#guardar-cambios-busqueda span,
#cancelar-cambios-busqueda span,
#finalizar-busqueda span,
#asignar-cambio-otra-busqueda span,
#reactivar_proceso span {
  display: block;
  margin: 30px auto;
  text-align: center;
  color: #003967;
  font-size: 16px;
  font-weight: 700;
}

#pausar-reanudar-busqueda .modal-body,
#guardar-cambios-busqueda .modal-body,
#cancelar-cambios-busqueda .modal-body,
#finalizar-busqueda .modal-body,
#asignar-cambio-otra-busqueda .modal-body,
#reactivar_proceso .modal-body {
  display: flex;
}

/* ........................................... */

.banner .content-banner .left .titulo a {
  color: #fff;
  text-decoration: none;
}

.banner .content-banner .left .hr a {
  color: #fff;
  text-decoration: none;
}

@media all and (max-width: 1200px) {
  .banner .content-banner{
      /*max-width: 800px;*/
      max-width: 1024px;
  }
  .menu .content-menu{
      /*max-width: 800px;*/
      max-width: 1024px;
  }
}

@media all and (max-width: 1024px) {
  .banner .content-banner{
    max-width: 800px;
  }
  .menu .content-menu{
    max-width: 800px;
  }
}

@media all and (max-width: 800px) {
  /* .banner{
  } */
  .hamburger {
      display: block !important;
  }
  .banner .content-banner{
    flex-direction: column-reverse;
    justify-content: center;
    height: 132px;
  }
  .banner .content-banner .left .titulo{
    font-size: 36px;
    margin-top: 15px;
  }
  .banner .content-banner .left .hr{
    font-size: 32px;
    margin-top: 15px;
  }
  .banner .content-banner .right{
    position: absolute;
    top: 14px;
    right: 10px;
  }
  .banner .content-banner .right .nombre{
    font-size: 15px;
    margin-right: 16px;
  }
  .banner .content-banner .right img{
    max-width: 23px;
  }
  .menu{
    /* margin: 10px 0; */
    height: 40px;
    bottom: -10px;
    top: auto;
  }
  .menu .content-menu .lista{
    display: none;
    background-color: rgba(0, 120, 198, 0.7);
    position: absolute;
    width: 100%;
    top: 40px;
    justify-content: center;
    align-items: center;
    z-index: 15;
  }
  .menu .content-menu{
    justify-content: flex-end;
  }
  .menu .content-menu .lista li{
    text-align: center;
    padding: 10px 0;
    margin: 0;
    width: 100%;
  }
  .menu .content-menu .lista.mobile li:hover{
    opacity: 0.5;
  }
  .menu .content-menu .lista.mobile{
    display: flex;
    width: 252px;
    right: 6%;
    flex-direction: column;
    z-index: 9999;
  }
}