.content-formulario {
  background-color: #ebebeb;
  width: 100%;
}

.content-formulario .formulario form .content-datos {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 100%;
  margin: 15px 0;
}

.content-formulario .formulario {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3% 0px;
}

.content-formulario .formulario form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Propiedad cambiada de end a flex-end *//* Propiedad cambiada de flex-end a flex-start */
}

.content-formulario .formulario form .content-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Propiedad cambiada de end a flex-end *//* Propiedad cambiada de flex-end a flex-start */
  width: 90%;
  margin: 0 auto;
  background-color: #fcfcfc;
  border-radius: 15px;
  padding: 10px 15px;
}

.content-formulario .formulario form .content-description .title{
  margin: 10px 0;
  color: #003967;
  font-size: 20px;
  font-weight: 700;
}

.content-formulario .formulario form .content-description .description {
  font-size: 18px;
  font-weight: 500;
}

.content-formulario .formulario form #asignar {
  width: 49%;
  height: 45px;
  padding: 5px;
  color: #0078c6;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 5px;
}

.content-formulario.aplicant_external .formulario form .content-datos input,
.content-formulario.aplicant_external .formulario form .content-datos select  {
  width: 100% !important;
  height: 45px;
  border: none;
  border-radius: 5px;
  margin: 15px 0;
  outline: none;
  padding: 5px 15px;
  color: #5d5c5c;
}


.content-formulario .formulario form .content-datos .content-linea {
  display: flex;
  justify-content: space-between;
}
.content-formulario .formulario form .content-datos .content-linea .opciones {
  display: flex;
  flex-direction: column;
}

.content-formulario .formulario form .content-datos .content-linea .opciones button.marcar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 5px 40px 5px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 15px 0;
  outline: none;
}

.content-formulario .formulario form .content-datos .content-linea .opciones button.marcar img {
  position: absolute;
  top: 4px;
  right: 7px;
  width: 100%;
  max-width: 25px;
}
.content-formulario .formulario form .content-datos .content-linea .opciones button.resaltar {
  background: transparent;
  border: 1px solid #8c8b8b;
  padding: 8px 40px 8px 15px;
  color: #5d5c5c;
  font-weight: 700;
  position: relative;
  margin: 0 0 15px;
  outline: none;
}

.content-formulario .formulario form .content-datos .content-linea .opciones button.resaltar img {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 100%;
  max-width: 22px;
}
.newapplicant .botones {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.botones span {
  color: #5d5c5c;
  display: block;
  margin: 5px 0;
  font-size: 12px;
}

.botones button.curriculum, .botones div.curriculum {
  background-color: #8c8b8b;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  margin: 15px 0 0;
  font-weight: 500;
  outline: none;
  line-height: 36px;
  cursor: pointer;
  width: 300px;
}

.botones .curriculum img {
  margin-right: 10px;
  border: none;
  width: 100%;
  max-width: 18px;
}

.botones button.crear {
  padding: 12px 15px;
  border: none;
  background-color: #006bb0;
  color: #ffff;
  font-weight: 500;
  border-radius: 5px;
  outline: none;
  line-height: 36px;
  margin: 15px 0 0;
}
.content-formulario .formulario span.Complete {
  color: #0078c6;
  font-size: 32px;
  line-height: 1;
  font-weight: 600;
  display: block;
}

.content-formulario .formulario span.Complete span {
  color: #003967;
}
.content-formulario .formulario form .content-datos .content-linea .input-error {
  width: 49%;
}

.content-formulario .formulario form .content-datos .content-linea .input-error span{
  color: #006bb0;
  font-size: 16px;
  margin-left: 5px;
}

.content-formulario .formulario form .content-datos .content-linea .input-error span.span-new-applicant-public{
  color: #0D2055;
  font-size: 16px;
  margin-left: 5px;
}

@media all and (max-width: 1200px) {
  .content-formulario .formulario{
    max-width: 1024px;
  }
}

@media all and (max-width: 755px) {
  .content-formulario .formulario form .content-datos{
      flex-direction: column;
  }
  .content-formulario .formulario form .content-datos .content-linea{
      flex-direction: column;
  }
  .content-formulario .formulario form .content-datos input{
      width: 100%;
  }
  .content-formulario .formulario form #asignar{
      width: 100%;
  }
}