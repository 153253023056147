.warning-message {
  color: red;
  font-weight: 700;
  font-size: 12px;
}
.spinner-border {
    display: flex;
    width: 5rem;
    height: 5rem;
    vertical-align: -.125em;
    border: .25em solid currentcolor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
}
.content-aprobar {
  background-image: url('../../../images/panel/back.png');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.content-aprobar .contenido {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
}
.search-button#search {
  border: none;
  background-color: #006bb0;
  color: #fff;
  padding: 10px 23px;
  margin: 0 5px;
  font-weight: 700;
  outline: none;
}
.parrafo-alta-user {
  font-size: larger;
}
.dataTables_wrapper .dataTables_filter input {
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
}
table#aprobar {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100% !important;
}
table#aprobar th {
  padding: 15px 10px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 5px 0;
  border: none;
  color: #003967;
  font-weight: 400;
  font-size: 14px;
}
table#aprobar tr {
  border-spacing: 5em;
  border: none;
}
#aprobar .pendiente td {
  background-color: #fff;
}
#aprobar .FINALIZADA td,
#aprobar .finalizada td,
#aprobar .finalizado td {
  background-color: #d6d6d6;
}
#aprobar td {
  color: #0078c6;
  padding-left: 10px;
  padding-right: 10px;
}
.content-filtrar {
  display: block;
  align-items: center;
  margin: 16px 0;
}
.content-filtrar .filtrar-span {
  color: #0078c6;
  font-weight: 700;
  font-size: 16px;
}
.content-filtrar select {
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  border: none;
  padding: 5px;
  width: 243px;
  color: #8c8b8b;
  margin-left: 15px;
}
@media all and (max-width: 500px) {
  .content-filtrar {
    display: flex;
  }
  .content-filtrar select {
    width: 200px;
  }
  div#aprobar_filter {
    display: flex;
  }
}
.content-filtrar.departament2 {
  margin-top: 70px;
}