.content-reportes {
  width: 100%;
  background-color: #ebebeb;
}

canvas {
  height: 100%;
}

.content-reportes .reportes {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding: 30px 0;
}

.content-reportes .reportes .selector-time {
  width: 100%;
  /* max-width: 1200px; */
  margin: 0px auto;
  padding: 30px 0;
}

.content-reportes .reportes .selector-time .dropdown {
  width: 100%;
  font-size: 17px;
  background: #efefef;
  height: 45px;
  border: 1px solid #bababa;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.content-reportes .reportes .selector-time .search-button {
  background-color: #006bb0;
  color: #fff;
  padding: 10px;
  font-size: 17px;
  border-radius: 5px;
  border: none;
  padding: 10px 23px;
  margin: 0 5px;
  font-weight: 700;
  outline: none;
}

.content-reportes .reportes .selector-time .time-interval-options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px 0;
}

.content-reportes
  .reportes
  .selector-time
  .time-interval-options
  .selector-month {
  width: 75%;
  padding: 5px;
  font-size: 17px;
  border-radius: 5px;
  background: #efefef;
  font-size: 17px;
  background: #efefef;
  height: 45px;
  border: 1px solid #bababa;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.content-reportes .reportes .selector-time .time-interval-options span {
  font-size: 18px;
}

.content-reportes
  .reportes
  .selector-time
  .time-interval-options
  input[type="date"] {
  width: 75%;
  padding: 5px;
  font-size: 17px;
  background: #efefef;
  height: 45px;
  border: 1px solid #bababa;
  outline: none;
  padding: 5px 15px;
}

.content-reportes .reportes .contenido .card {
  background-color: #fff;
  margin: 0 5px;
  /* height: 335px; */
  height: 100%;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
}

.card .content-title {
  display: flex;
  font-size: 25px;
}

.card .content-title .title {
  width: 80%;
}

.card .content-title .number {
  width: 20%;
  text-align: center;
}

.card .graph {
  margin: 10px 0;
  height: 300px;
}

.card .download-section {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.boton-descargar-excel {
  background-color: #287233;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  border: none;
}

.content-reportes .reportes .contenido .card-small {
  background-color: #fff;
  margin: 0 5px;
  height: 170px;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
}

.card-small .content {
  display: flex;
  flex-direction: column;
}

.card-small .content .title {
  font-size: 20px;
  font-weight: 600;
}

.card-small .content .number {
  position: absolute;
  /* margin-top: 50px; */
  bottom: 30px;
  right: 30px;
  font-size: 30px;
  font-weight: 600;
  text-align: end;
}

.card-small .download-section {
  position: absolute;
  /* margin-top: 50px; */
  bottom: 30px;
  left: 30px;
  /* font-size: 30px;
  font-weight: 600;
  text-align: end; */
}

.margin-t {
  margin-bottom: 10px;
}

@media all and (max-width: 767px) {
  .content-reportes .reportes .selector-time select {
    width: 100%;
  }

  .card-small .content .title {
    font-size: 17px;
    font-weight: 600;
  }

  .card-small .content .number {
    position: absolute;
    /* margin-top: 50px; */
    bottom: 30px;
    right: 30px;
    font-size: 25px;
    font-weight: 600;
    text-align: end;
  }
}

@media all and (max-width: 575px) {
  .content-reportes .reportes .selector-time select.dropdown {
    width: 100%;
  }

  .content-reportes .reportes .contenido .card {
    height: auto;
  }

  .content-reportes .reportes .contenido .card-small {
    background-color: #fff;
    margin: 0 5px;
    height: 170px;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
    z-index: 1;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.52);
  }

  .card-small .content .title {
    font-size: 20px;
    font-weight: 600;
  }

  .card-small .content .number {
    position: absolute;
    /* margin-top: 50px; */
    bottom: 30px;
    right: 30px;
    font-size: 25px;
    font-weight: 600;
    text-align: end;
  }
}

/* .content-reportes .reportes .fila1 {
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 20px 0;
}
.content-reportes .reportes .card {
  background-color: #fff;
  margin: 0 12px;
  height: 335px;
  border-radius: 30px;
  padding: 30px;
  position: relative;
  z-index: 1;
  
}
.contenido {
  display: flex;
  
}

.contenido .left {
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
}

.contenido .left .titulo {
  color: #003967;
  display: block;
  font-size: 30px;
  font-weight: 300;
}

.contenido .left .leyenda {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contenido .left .leyenda .item {
  display: flex;
  flex-direction: column;
}

.contenido .left .leyenda .item .texto {
  display: flex;
  justify-content: space-between;
}

.contenido .left .leyenda .item .texto span {
  font-size: 14px;
  font-weight: 400;
  color: #5e5d5c;
}

.contenido .left .leyenda .item .barra {
  width: 100%;
  height: 5px;
  background-color: #ebebeb;
  border-radius: 10px;
  position: relative;
  margin-bottom: 5px;
}

.contenido .left .leyenda .item .barra .progreso {
  height: 5px;
  border-radius: 10px;
}

.contenido .right {display: flex;flex-direction: column;width: 40%;align-items: center;justify-content: space-between;}

.contenido .right .cantidad {
  color: #003967;
  display: block;
  font-size: 30px;
  font-weight: 300;
  font-weight: 600;
}
.content-reportes .reportes .card .contenido-corto {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  
}

.content-reportes .reportes .card .contenido-corto .titulo {
  color: #003967;
  font-size: 33px;
  line-height: 1;
  width: 100%;
}

.content-reportes .reportes .card .contenido-corto .cantidad {
  color: #003967;
  font-weight: 600;
  font-size: 43px;
  line-height: 1;
}
.content-reportes .reportes .card .contenido-corto .dias {
  display: flex;
  flex-direction: column;
}

.content-reportes .reportes .card .contenido-corto .dias span.promedio {
  color: #5e5d5c;
  font-size: 14px;
  line-height: 1.4;
}
.content-reportes .reportes .fila2 {
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 20px 0;
}

.content-reportes .reportes .fila2 .card {
  width: 23%;
}

.content-reportes .reportes .fila2 .corto {
  
  background: no-repeat;
  border: none;
  padding: 0;
  display: flex;
}

.content-reportes .reportes .fila2 .corto .card {
  height: 159px;
  width: 100%;
}
.content-reportes .reportes .fila2 .corto .card .titulo {
  color: #003967;
  font-size: 33px;
  line-height: 1;
  width: 100%;
  display: block;
}



@media all and (max-wigth: 1215px) {
  .content-reportes .reportes .fila1 .card {
    display: flex;
    width: 48%;
  }
} */
