.content-login{
  background-image: url('../../images/login/back.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.content-login .contenido {
  display: block;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20%;
  display: flex;
  justify-content: space-between;
}

.content-login .contenido .content-balanz {
  display: flex;
  flex-direction: column;
}

.content-login .contenido .content-balanz img {
  display: block;
  width: 100%;
  max-width: 424px;
}

.content-login .contenido .content-balanz .hr {
  color: #fff;
  font-size: 61px;
  line-height: 1;
  font-weight: 300;
}

.content-login .contenido .content-balanz .hr span {
  color: #d2d1d1;
}


.content-login .contenido .content-form form {
  display: flex;
  flex-direction: column;
}

.content-login .contenido .content-form form input#usuario {
  width: 300px;
  height: 45px;
  padding: 15px;
  outline: none;
  border-radius: 5px;
}

.content-login .contenido .content-form form input#contrasena {
  width: 300px;
  height: 45px;
  padding: 15px;
  margin-top: 20px;
  outline: none;
  border-radius: 5px;
}

.error {
  border: red solid;
  transition: all 0.5s ease;
}

.content-login .contenido .content-form form input#ingresar,
.content-login .btn-ingresar,
.username .come-back-action {
  width: 200px;
  margin-top: 20px;
  height: 45px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(0deg, rgba(167,184,238,1) 0%, rgba(0,120,198,1) 28%);
  border: none;
  border-radius: 5px;
}

.content-login .contenido .content-form form input#ingresar:hover,
.content-login .btn-ingresar:hover,
.username .come-back-action:hover {
  opacity: 0.9;
}

@media all and (max-width: 1200px) {
  .content-login .contenido{
    max-width: 800px;
  }
}

@media all and (max-width: 800px) {
  .content-login .contenido{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content-login .contenido .content-form form{
    margin-top: 50px;
  }
}

@media all and (max-width: 480px) {
  .content-login .contenido .content-balanz img{
    max-width: 300px;
  }
  .content-login .contenido .content-balanz .hr{
    font-size: 44px;
  }
}

.loading-wrapper{
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.loading-wrapper .content h3{
  margin-bottom: 0;
  color: #fff;
}
.loading {
  display: inline-block;
  width: 30px;
  min-height:30px;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin-right: 10px;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

.username .come-back-action{
  width: auto;
  margin-top: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.username .come-back-action img{
  width: 20px;
}