.header-empleos {
  background-color: #0D2055;
}

.secction-banner {
  background-color: #0B1F51;
  height: 300px;
  color: #0B1F51;
}

.productTitleEmpleos  {
  max-width: 34%;
  font-size: 2rem;
  color: #0D2055;
  font-weight: bold;
}